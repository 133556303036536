import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as React from "react";
import { SubtleButton } from "./buttons";
import {
  getDialogMethods,
  makeDialog,
  Dialog,
} from "./dialogTools/DialogManager";
import { useEffect } from "react";

export interface JustOkDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  okayText?: string;
}
const keyDownHandler = (event: KeyboardEvent) => {
  // Prevent keys from being handled in the underlying page.
  event.stopPropagation();
};

export const JustOkDialog: React.FunctionComponent<JustOkDialogProps> = (
  props
) => {
  const { onClose, open, okayText, title } = props;
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler, true);
    return () => {
      document.removeEventListener("keydown", keyDownHandler, true);
    };
  });

  return (
    <MuiDialog
      maxWidth="xs"
      aria-labelledby="justok-dialog-title"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="justok-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        <SubtleButton onClick={handleClose}>{okayText ?? "Ok"}</SubtleButton>
      </DialogActions>
    </MuiDialog>
  );
};

interface InlineJustOkDialogProps {
  title: string;
  okayText?: string;
  content: string | JSX.Element;
}

let inlineJustOkDialogInternal: Dialog<boolean, InlineJustOkDialogProps>;

export const openJustOkDialog = (props: InlineJustOkDialogProps) => {
  if (!inlineJustOkDialogInternal) {
    inlineJustOkDialogInternal = makeJustOkDialog();
  }
  return getDialogMethods().open(inlineJustOkDialogInternal, props);
};

const makeJustOkDialog = () => {
  const dialog = makeDialog<boolean, InlineJustOkDialogProps>({
    name: "JustOkConfirmationDialog",
    componentRenderer: (props) => {
      return (
        <JustOkDialog
          onClose={() => {
            props.close(true, true);
          }}
          open={true}
          title={props.props.title}
          okayText={props.props.okayText}
        >
          {props.props.content}
        </JustOkDialog>
      );
    },
  });
  return dialog;
};
