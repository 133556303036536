import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import * as React from "react";
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export type SnackbarSeverity = "error" | "success" | "warning" | "info";
export function CommonSnackbar(props: {
  message: JSX.Element | string;
  closeFunc: () => void;
  severity: SnackbarSeverity;
}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    props.closeFunc();
    setOpen(false);
  };

  React.useEffect(() => {
    if (props.message) setOpen(true);
  });

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={props.severity}>
        {props.message === undefined ? null : props.message}
      </Alert>
    </Snackbar>
  );
}

export function QuickSnackbar(props: {
  message: JSX.Element | string;
  closeFunc: () => void;
  severity: SnackbarSeverity;
  autoClose?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    props.closeFunc();
    setOpen(false);
  };

  React.useEffect(() => {
    if (props.message) setOpen(true);
  });

  if (props.message === undefined) return null;
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={props.autoClose ? 2000 : null}
    >
      <Alert severity={props.severity}>{props.message}</Alert>
    </Snackbar>
  );
}

export function ErrorSnackbar(props: {
  message: JSX.Element | string;
  show: boolean;
  onShow: () => void;
}) {
  const [open, setOpen] = React.useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (props.show) {
      setOpen(true);
      props.onShow();
    }
  });

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity="error">{props.message}</Alert>
    </Snackbar>
  );
}
