import { Box, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { getLevelFromScore, Level } from "../../../domain/types";
import { Answer } from "../spell_types";

const sortedAnswerComponent = (answers: Array<Answer>) =>
  answers
    .sort((a, b) => {
      if (a.word < b.word) {
        return -1;
      }
      if (a.word > b.word) {
        return 1;
      }
      // a must be equal to b
      return 0;
    })
    .map((answer, index) => {
      return (
        <Box key={index} mr={1}>
          <Typography>
            <b>{answer.word}</b> {answer.score}
          </Typography>
        </Box>
      );
    });

const logoBlue = "#0055aa";
const logoYellow = "#FFCB05";

const vw = 400;
const vh = 60;
const achievedColor = logoBlue;
const highestAchievedColor = logoYellow;
const unachievedColor = "black"; //"#9a9ea1";
const rungRadius = 5;
const progressMarkRadius = rungRadius + 2;
const ladderLength = vw - 2 * rungRadius;
const ladderX = rungRadius;
const ladderY = progressMarkRadius;

export interface ProgressComponentProps {
  foundWords: string[];
  answers: Answer[];
  gameAnswerCount: number;
  levels: Level[];
  score: number;
}
export const ProgressComponent = (props: ProgressComponentProps) => {
  const { foundWords, answers, gameAnswerCount, levels, score } = props;

  if (foundWords == null) return null;
  // Create array with only the found answers
  const foundAnswers = foundWords.map((word) =>
    answers.find((answer) => answer.word === word)
  );

  const [newKey, setNewKey] = useState(0);
  const [levelName, setLevelName] = useState("");
  const levelNameFromScore = getLevelFromScore(levels, score).name;
  const highestLevelScore = levels[levels.length - 1].score;
  const progressMarkerX = ladderX + (score / highestLevelScore) * ladderLength;

  const isLevelChanged = levelNameFromScore !== levelName;
  if (isLevelChanged) setNewKey(newKey + 1);
  const animatedLevelName = isLevelChanged ? null : (
    <Box>
      <svg
        key={newKey}
        viewBox={`0 0 ${vw} ${vh}`}
        width={vw}
        height={vh}
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1={ladderX}
          y1={vh - ladderY}
          x2={ladderX + ladderLength}
          y2={vh - ladderY}
          stroke="black"
          strokeWidth="5"
        />
        {(() => {
          // Generate a point for each level
          const levelCount = levels.length;
          const rungSpace = ladderLength / (levelCount - 1);
          return levels.map((level, index) => {
            let color = unachievedColor;
            const achieved = score >= level.score;
            if (achieved) {
              if (index === levelCount - 1 || levels[index + 1].score > score) {
                color = highestAchievedColor;
              } else {
                color = achievedColor;
              }
            }
            return (
              <circle
                key={index}
                // cx={ladderX + rungSpace * index}
                cx={
                  ladderX +
                  (levels[index].score / highestLevelScore) * ladderLength
                }
                cy={vh - ladderY}
                r={rungRadius}
                fill={color}
              />
            );
          });
        })()}
        <line // progress marker
          x1={progressMarkerX}
          x2={progressMarkerX}
          y1={vh - ladderY - progressMarkRadius}
          y2={vh - ladderY + progressMarkRadius}
          stroke={highestAchievedColor}
          strokeWidth="2"
        />
        <text
          x="200"
          y="40"
          textAnchor="middle"
          fontSize="40"
          fontWeight={700}
          fill={logoYellow}
        >
          {getLevelFromScore(levels, score).name}
          <animate attributeName="font-size" values="20;60;40" dur="1s" />
          <animate
            attributeName="fill"
            values={`${logoBlue};${logoYellow}`}
            dur="2s"
          />
        </text>
      </svg>
    </Box>
  );

  if (isLevelChanged) setLevelName(levelNameFromScore);
  return (
    <Grid container direction="column" alignItems="center">
      <Box m={0.2} />
      {(() => {
        const foundWordsCount = foundWords.length;
        let wordCountMessage: string;
        const wordForm = foundWordsCount === 1 ? "word" : "words";
        const pointForm = score === 1 ? "point" : "points";
        switch (foundWordsCount) {
          case 0:
            wordCountMessage = `You have ${gameAnswerCount} ${wordForm} to find.`;
            break;
          case gameAnswerCount:
            if (foundWordsCount === 1) {
              wordCountMessage = `Congratulations! You found the word:`;
            } else {
              wordCountMessage = `Congratulations! You found all ${foundWordsCount} ${wordForm}:`;
            }
            break;
          default:
            wordCountMessage = `You found ${foundWordsCount} ${wordForm} for ${score} ${pointForm}:`;
            break;
        }
        return (
          <Grid container direction="column" alignItems="center">
            {animatedLevelName}
            <Grid item>{wordCountMessage}</Grid>
          </Grid>
        );
      })()}
      <Grid container justify="center">
        {sortedAnswerComponent(foundAnswers)}
      </Grid>
    </Grid>
  );
};
