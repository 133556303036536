import * as React from "react";
import { createContext, useState } from "react";
import * as ReactDOM from "react-dom";

export interface DialogResponse<TResult> {
  isOkay: boolean;
  result: TResult;
}

interface DialogComponentRenderer<TResult, TProps> {
  (renderProps: {
    close: (isOkay: boolean, result: TResult) => void;
    props: TProps;
  }): JSX.Element;
}
export interface Dialog<TResult, TProps> {
  name: string;
  componentRenderer: DialogComponentRenderer<TResult, TProps>;
  isOpen: boolean;
  result?: TResult;
  resolve?: (result: DialogResponse<TResult>) => void;
}

interface DialogManagerProps {}
interface DialogMethods {
  open: <TResult, TProps>(
    dialog: Dialog<TResult, TProps>,
    props?: TProps
  ) => Promise<DialogResponse<TResult>>;
  openByName: <TResult, TProps>(
    dialogName: string,
    props?: TProps
  ) => Promise<DialogResponse<TResult>>;
}

const dialogTable: Record<
  string,
  { dialog: Dialog<any, unknown>; props?: unknown }
> = Object.create(null);
let dialogIndex = 0;

const register = <TResult, TProps>(args: {
  name: string;
  componentRenderer: DialogComponentRenderer<TResult, TProps>;
}) => {
  const { componentRenderer } = args;
  let name = args.name;
  if (name === undefined) {
    // auto-assign name
    dialogIndex++;
    name = "__autonamed__" + dialogIndex.toString();
  }
  const dialog = { name, componentRenderer, isOpen: false };
  dialogTable[name] = { dialog };
  return dialog;
};

let dialogMethodsLocal: DialogMethods;
export const getDialogMethods = () => dialogMethodsLocal;

export const DialogManagerContext = createContext<DialogMethods>(null);

export const DialogManager: React.FunctionComponent<DialogManagerProps> = (
  props
) => {
  const [stateIndex, setStateIndex] = useState(0);

  const dialogOpen = <TResult, TProps>(
    dialog: Dialog<TResult, TProps>,
    props: TProps
  ) => {
    dialog.isOpen = true;
    dialogTable[dialog.name].props = props;
    setStateIndex(stateIndex + 1);
    return new Promise<DialogResponse<TResult>>((resolve, reject) => {
      dialog.resolve = resolve;
    });
  };
  const dialogMethods: DialogMethods = {
    open: dialogOpen,
    openByName: <TProps,>(name: string, props: TProps) => {
      return dialogOpen(dialogTable[name].dialog, props);
    },
  };
  dialogMethodsLocal = dialogMethods;
  return (
    <DialogManagerContext.Provider value={dialogMethods}>
      {Object.values(dialogTable).map((dialogTableItem, index) => {
        if (dialogTableItem.dialog.isOpen) {
          const close = <TResult,>(isOkay: boolean, result: TResult) => {
            dialogTableItem.dialog.isOpen = false;
            dialogTableItem.dialog.resolve({ isOkay, result });
            setStateIndex(stateIndex + 1);
          };
          return (
            <React.Fragment key={index}>
              <Defer
                renderer={() =>
                  dialogTableItem.dialog.componentRenderer({
                    close,
                    props: dialogTableItem.props,
                  })
                }
              />
            </React.Fragment>
          );
        }
        return null;
      })}
      <>{props.children}</>
    </DialogManagerContext.Provider>
  );
};

// Defer so that hooks are called when dialog is rendered as a child,
// not as part of parent render.
// Otherwise the parent calls different hooks at different times.
const Defer = (props: { renderer: () => JSX.Element }) => {
  return ReactDOM.createPortal(
    props.renderer(),
    document.getElementById("dialog")
  );
};

export const makeDialog = <TResult, TProps>(args: {
  name?: string;
  componentRenderer: DialogComponentRenderer<TResult, TProps>;
}) => {
  const { name, componentRenderer } = args;
  return register({
    name,
    componentRenderer,
  });
};
