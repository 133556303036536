import { useState, useRef } from "react";

// Fresh means:
// * value not captured by closure
// * value is gettable syncronously
export const useFreshState = <TState>(
  f: () => TState
): [() => TState, (s: TState) => void] => {
  const currentState = useRef<TState>(undefined);
  const [_reactState, setReactState] = useState(() => {
    const initialValue = f();
    currentState.current = initialValue;
    return initialValue;
  });
  const setter = (s: TState) => {
    currentState.current = s;
    setReactState(s);
  };
  const getter = () => {
    return currentState.current;
  };
  return [getter, setter];
};
