import { ApiResponseHeader } from "../../../domain/serverContract";
import { ErrorResponse } from "../serverContract";
import { makeRequest } from "../../../http/http";

export interface ShareGameRequest {
  gameRef: string;
  progress: unknown;
}
export interface ShareGameResponse extends ApiResponseHeader {
  gameRef: string;
}

interface ResponseInternal extends ErrorResponse, ShareGameResponse {}

export async function shareGame(request: ShareGameRequest) {
  return await makeRequest<ResponseInternal>(request, "/api/share_game");
}
