import { Box, Container } from "@material-ui/core";
import * as React from "react";
import { SpellPlaySpec } from "../spell_types";
import { GameInfo } from "./SpellGameComponent";

export interface SpellRulesComponentProps {
  playSpec: SpellPlaySpec;
  gameInfo: GameInfo;
}
export const SpellRulesComponent = (props: SpellRulesComponentProps) => {
  const { playSpec, gameInfo } = props;
  const rulesPrologue = playSpec.rulesPrologue;
  const rulesPrologueComponent = !rulesPrologue ? null : (
    <Container>{rulesPrologue}</Container>
  );
  return (
    <Box>
      {rulesPrologueComponent}
      <Box m={1} />
      <Container>
        The following rules apply to this game:
        <ul style={{ marginTop: 0 }}>
          <RequiredLettersInfoComponent
            requiredLetters={playSpec.requiredLetters}
          />
          <li>
            <b>{`Letters may ${
              gameInfo.canReuseLetters ? "" : "not "
            } be reused.`}</b>
          </li>
          <li>The shortest word has {gameInfo.minLength} letters.</li>
          <li>
            There{" "}
            {gameInfo.pangramCount === 1
              ? "is one word"
              : `are ${gameInfo.pangramCount} words`}{" "}
            that use{gameInfo.pangramCount === 1 ? "s" : ""} all of the letters.
          </li>
          <li>
            {`This game has a total of
  ${playSpec.answers.length} possible words worth ${gameInfo.maxPoints} points.
  `}
            {gameInfo.highestLevel.score >= gameInfo.maxPoints
              ? ""
              : " You don't need to find all of the words to achieve the top level."}
          </li>
        </ul>
      </Container>
      <Box m={1} />
      <Container>
        The game levels and the number of points needed to reach each level are:{" "}
        {props.playSpec.levels.map((level, index) => (
          <span key={index}>
            {index > 0 ? ", " : ""}
            <b>{level.name}</b> {level.score}
          </span>
        ))}
        .
      </Container>
      <Box m={2} />
    </Box>
  );
};

export const makeSeparatedElementStringFromList = (
  list: string[]
): JSX.Element[] => {
  if (list === undefined) return undefined;
  return list.reduce((result, item, index) => {
    if (index !== 0) result.push(<span key={2 * index}>, </span>);
    result.push(<b key={2 * index + 1}>{item}</b>);
    return result;
  }, [] as JSX.Element[]);
};

const RequiredLettersInfoComponent = (props: {
  requiredLetters: string;
}): JSX.Element => {
  const { requiredLetters } = props;
  if (!requiredLetters?.length) return null;
  return (
    <li>
      Each word must include the required letter
      {requiredLetters.length === 1 ? ": " : "s: "}
      {makeSeparatedElementStringFromList(
        [...(requiredLetters ?? [])].map((item) => item.toUpperCase())
      )}
      .
    </li>
  );
};
