import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "center",
    },
  });
});
interface RowContainerProps {
  center?: boolean;
  width?: string;
  className?: string;
}

export const RowContainer: React.FunctionComponent<RowContainerProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.container, props.className)}
      style={{
        justifyContent: props.center ? "center" : "normal",
        width: props.width ?? "100%",
      }}
    >
      {props.children}
    </div>
  );
};
