import * as React from "react";
import { Container, Box, Button } from "@material-ui/core";
import { useEffect, useRef } from "react";
import {
  readStateFromLocalStorage,
  storeProgressLocally,
} from "../localStorage";
import { ShareGameRequest, shareGame } from "../requests/shareGame";
import { openJustOkDialog } from "../../../components/JustOkDialog";
import { getBaseUrl } from "../url";
import { notifySuccess } from "../../../components/NotificationManager";

export interface ShareProgressComponentProps {
  showShareProgressUrl: string;
}
export const ShareProgressComponent = (props: ShareProgressComponentProps) => {
  const { showShareProgressUrl } = props;
  const shareUrlRef = useRef(null);
  useEffect(() => {
    // Preselect the URL so that the copy will copy the entire URL
    if (showShareProgressUrl === "") return;
    let element: HTMLSpanElement = shareUrlRef.current;
    if (element === null) return;
    const s = window.getSelection();
    const r = document.createRange();
    r.selectNodeContents(element);
    s.removeAllRanges();
    s.addRange(r);
  });
  return (
    <Container>
      To share your progress, copy the link below to the clipboard and send it
      (for example, using email or chat). If you want to resume this game later
      on another device, then send it to yourself. Shared progress is saved for
      ten days.
      <Box boxShadow={4} m={1}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            padding: "0.5rem",
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <span
              id="shareProgress"
              ref={shareUrlRef}
              style={{ overflowWrap: "anywhere", userSelect: "all" }}
            >
              {showShareProgressUrl}
            </span>
          </div>
          <div>
            <Box m={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={async (event) => {
                  document.execCommand("copy");
                  if (navigator.clipboard) {
                    await navigator.clipboard.writeText(showShareProgressUrl);
                  }
                  notifySuccess("Copied");
                }}
              >
                Copy to Clipboard
              </Button>
            </Box>
          </div>
        </div>
      </Box>
    </Container>
  );
};

export const shareProgress = async (gameRef: string, progress: unknown) => {
  // If the version in local storage is the same as what is on the server, then no need to create a new game
  // Unless it is old
  const localState = readStateFromLocalStorage(gameRef);
  // If there has been no sharing on this device/tab, then the sharedGameRef will be undefined.
  // In that case, we use the gameRef, which should be the same as the ref in the window location.
  let sharedGameRef = localState.sharedGameRef ?? localState.gameRef;
  if (
    localState.unmodified === false ||
    localState.lastShareTime == null ||
    localState.lastShareTime.getTime() - Date.now() > 7200 // Definition of old in ms
  ) {
    const shareGameRequest: ShareGameRequest = {
      gameRef: gameRef,
      progress,
    };
    const response = await shareGame(shareGameRequest);
    sharedGameRef = response.gameRef;
    storeProgressLocally(
      sharedGameRef,
      (data) => (data.unmodified = true),
      true // this is an update to shared progress
    );
  }
  openJustOkDialog({
    content: (
      <ShareProgressComponent
        showShareProgressUrl={getBaseUrl() + "/" + sharedGameRef}
      />
    ),
    title: "Share Progress",
  });
};
