import * as React from "react";

interface HiderProps {
  hidden: boolean;
  // unomunt = true means unmount when hidden
  unmount?: boolean;
}

const Hider: React.FunctionComponent<HiderProps> = (props) => {
  if (props.hidden && props.unmount) return null;
  return (
    <div style={{ display: props.hidden ? "none" : null }}>
      {!props.hidden && props.children}
    </div>
  );
};
export default Hider;
