import { Button } from "@material-ui/core";
import { RadioButtonUncheckedOutlined } from "@material-ui/icons";
import * as React from "react";

export interface InputButtonComponentProps {
  letter: string;
  isRequired: boolean;
  onClick: () => void;
}

// InputButtonComponent presents a button in a custom keyboard.
export const InputButtonComponent = (props: InputButtonComponentProps) => {
  const { isRequired, letter, onClick } = props;

  return (
    <Button
      variant="contained"
      color={isRequired ? "secondary" : "primary"}
      onClick={() => {
        onClick();
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>{letter}</div>
        {isRequired && (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <RadioButtonUncheckedOutlined style={{ fontSize: "2rem" }} />
          </div>
        )}
      </div>
    </Button>
  );
};
