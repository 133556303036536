import { getGameRefFromLocation } from "./url";
import { getGameType } from "./globalPlayData";

interface LocalStorageData<TProgress> {
  progress: TProgress;
  gameRef: string;
  sharedGameRef?: string;
  unmodified: boolean; // true if a copy from server, false if locally modified
  lastShareTime: Date;
}

interface LocalStorageDataInternal<TProgress>
  extends LocalStorageData<TProgress> {
  lastModifiedTime: Date;
}

export function localStorageAvailable() {
  try {
    var t = "t";
    localStorage.setItem(t, t);
    localStorage.removeItem(t);
    return true;
  } catch (_e) {
    return false;
  }
}

export function clearLocalStorage() {
  if (localStorageAvailable()) {
    localStorage.clear();
  }
}

export function clearLocalStorageForGame() {
  const gameRef = getGameRefFromLocation();
  if (localStorageAvailable()) {
    localStorage.removeItem(getGameType() + "/" + gameRef);
  }
}

export function storeProgressLocally(
  gameRef: string,
  mutator: (localStorageData: LocalStorageData<unknown>) => void,
  isSharingProgress: boolean
) {
  if (!localStorageAvailable) return;
  let ls = readStateFromLocalStorageInternal(gameRef);
  if (!ls) {
    // Nothing stored yet
    ls = {
      gameRef,
      lastModifiedTime: undefined,
      lastShareTime: undefined,
      progress: undefined,
      unmodified: true,
    };
  }
  mutator(ls);
  writeStateToLocalStorage(ls, isSharingProgress);
}

function writeStateToLocalStorage<TProgress>(
  localStorageData: LocalStorageData<TProgress>,
  isSharingProgress: boolean
) {
  if (localStorageAvailable()) {
    // If no sharedGameRef is supplied, then we keep it as it was.
    const ls = readStateFromLocalStorageInternal(localStorageData.gameRef);
    const newData: LocalStorageDataInternal<TProgress> = {
      sharedGameRef: ls?.sharedGameRef,
      ...localStorageData,
      lastModifiedTime: new Date(),
      lastShareTime: isSharingProgress ? new Date() : ls?.lastShareTime,
    };
    localStorage.setItem(
      getGameType() + "/" + newData.gameRef,
      JSON.stringify(newData)
    );
  }
}

export function readStateFromLocalStorage<TProgress>(gameRef: string) {
  const internal = readStateFromLocalStorageInternal(gameRef);
  return internal as LocalStorageData<TProgress>;
}

function readStateFromLocalStorageInternal<TProgress>(gameRef: string) {
  if (localStorageAvailable()) {
    const gameStateText = localStorage.getItem(getGameType() + "/" + gameRef);
    try {
      const localStorageDataInternal = JSON.parse(
        gameStateText
      ) as LocalStorageDataInternal<TProgress>;
      return localStorageDataInternal;
    } catch (_e) {
      return undefined;
    }
  }
}
