import * as React from "react";
import { ConfirmationDialogBase } from "./dialogTools/ConfirmationDialogBase";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "./dialogTools/DialogManager";

interface InlineConfirmationDialogProps {
  title: string;
  okayText?: string;
  content: string | JSX.Element;
}

export const makeInlineConfirmationDialog = () => {
  const dialog = makeDialog<boolean, InlineConfirmationDialogProps>({
    name: "InlineConfirmationDialog",
    componentRenderer: (props) => {
      return (
        <ConfirmationDialogBase
          onClose={(isOkay) => {
            props.close(isOkay, undefined);
          }}
          open={true}
          title={props.props.title}
          okayText={props.props.okayText}
        >
          {props.props.content}
        </ConfirmationDialogBase>
      );
    },
  });
  return dialog;
};

let inlineConfirmationDialogInternal: Dialog<
  boolean,
  InlineConfirmationDialogProps
>;
export const openConfirmationDialog = (
  props: InlineConfirmationDialogProps
) => {
  if (!inlineConfirmationDialogInternal) {
    inlineConfirmationDialogInternal = makeInlineConfirmationDialog();
  }
  return getDialogMethods().open(inlineConfirmationDialogInternal, props);
};
