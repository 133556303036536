// URL structure
// gameUrl: URL for game itself:
//  <origin>/run/<gameRef>
// progress URL: URL for game + progress:
//  Same as above, except the gameRef starts with "p-"
// baseUrl refers to the string <origin>/rull

// gamePath is /<gameRef>
export const getGameRefFromLocation = (): string => {
  const gamePath = getGamePathFromLocation();
  const tokens = gamePath.split("/");
  const count = tokens.length;
  return count > 0 ? tokens[0] : undefined;
};

const getGamePathFromLocation = () => {
  const path = window.location.pathname;
  let tokens = path.split("/");
  // Get rid of /run/
  // token[0] is length 0, since first char is /
  tokens.shift();
  tokens.shift();
  return tokens.join("/");
};

export const getBaseUrl = () => {
  const path = window.location.pathname;
  let tokens = path.split("/");
  // token[0] is length 0, since first char is /
  return [
    window.location.origin,
    tokens[1], // run
  ].join("/");
};
