export const min = <T>(items: T[]): T => {
  // undefined is min
  return items.reduce((result, item) => {
    if (item === undefined || result === undefined) return undefined;
    if (item < result) return item;
    return result;
  }, items[0]);
};

export const max = <T>(items: T[]): T => {
  // undefined is max
  return items.reduce((result, item) => {
    if (item === undefined || result === undefined) return undefined;
    if (item > result) return item;
    return result;
  }, items[0]);
};

export const sum = (a: number[]) => a.reduce((sum, n) => (sum += n), 0);

export const makeSeparatedStringFromList = (list: string[]): string => {
  if (list === undefined) return undefined;
  return list.reduce((result, item, index) => {
    if (index === 0) return item;
    return result + ", " + item;
  }, "");
};

export const round = (x: number) => Math.round(x);

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

let serialIdCounter = -1;
export const serialId = () => ++serialIdCounter;

// pickout removes pickLetters from the source.
// Any letter in pickLetters is removed from the source.
export const pickout = (pickLetters: string, source: string) => {
  if (source == null || source.length === 0) return source;
  return [...(source ?? [])].reduce((result, letter) => {
    if (pickLetters.indexOf(letter) >= 0) return result;
    return result + letter;
  }, "");
};

export const delay = (promise: Promise<unknown>, ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms)).then(() => promise);
};

export const delay2 = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// Assumes no duplicates in either list
// Merges without additional duplication
export const mergeWordLists = (lista: string[], listb: string[]) => {
  lista = lista ?? [];
  listb = listb ?? [];
  return listb.reduce((result, wordb) => {
    if (lista.includes(wordb)) return result;
    result.push(wordb);
    return result;
  }, lista);
};

// Assumes no duplicates in either list
export const intersection = (lista: string[], listb: string[]) => {
  lista = lista ?? [];
  listb = listb ?? [];
  return listb.reduce((result, wordb) => {
    if (lista.includes(wordb)) {
      result.push(wordb);
      return result;
    }
    return result;
  }, [] as string[]);
};

// eliminates duplicates
export const union = (lista: string[], listb: string[]) => {
  lista = lista ?? [];
  listb = listb ?? [];
  const dict: Record<string, boolean> = Object.create(null);
  lista.forEach((item) => (dict[item] = true));
  listb.forEach((item) => (dict[item] = true));
  return Object.keys(dict);
};

// Much faster to use loops with indices
// Returns list of duplicates
export const findDuplicates = (list: string[]) => {
  const lista = [...(list ?? [])];
  return list.reduce((result, word) => {
    lista.shift();
    if (lista.includes(word)) {
      result.push(word);
      return result;
    }
    return result;
  }, []);
};

export const pluralize = (count: number, word: string) => {
  if (count === 1) return `${count} ${word}`;
  return `${count} ${word}s`;
};

export const shuffle = (array: Array<string>) => {
  const newArray = [...array];
  while (true) {
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    // Don't return the items in the same order.
    if (newArray.length < 2 || newArray.join("") !== array.join(""))
      return newArray;
  }
};

export const shuffleString = (text: string) => {
  return shuffle(text.split("")).join("");
};

// isAnagramOf returns true if a is an anagram of b.
export const isAnagramOf = (a: string, b: string) => {
  if (a == null || b == null) return false;
  return [...a].sort().join("") === [...b].sort().join("");
};
