import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    primaryButton: {
      margin: "0.2rem 0.2rem 0.2rem 0.2rem",
    },
    secondaryButton: {
      margin: "0.2rem 0.2rem 0.2rem 0.2rem",
      backgroundColor: "lightgray",
    },
    strongGuidingButton: {
      margin: "0.2rem 0.2rem 0.2rem 0.2rem",
      backgroundColor: theme.palette.secondary.main,
    },
    mildGuidingButton: {
      margin: "0.2rem 0.2rem 0.2rem 0.2rem",
    },
    subtleButton: {
      margin: "0.2rem 0.2rem 0.2rem 0.2rem",
    },
  });
});
interface ButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  "aria-label"?: string;
  type?: "submit" | "button";
  autoFocus?: boolean;
  color?: "inherit" | "primary" | "secondary" | "default";
}

export const PrimaryButton: React.FunctionComponent<ButtonProps> = (props) => {
  const classes = useStyles();
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    // wrap in div to prevent button from expanding to full width in wide components
    <div>
      <Button
        className={classes.primaryButton}
        variant="contained"
        color={props.color ?? "primary"}
        aria-label={props["aria-label"]}
        disabled={props.disabled}
        onClick={onClick}
        type={props.type ?? "button"}
        autoFocus={props.autoFocus}
      >
        {props.children}
      </Button>
    </div>
  );
};

export const SecondaryButton: React.FunctionComponent<ButtonProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.secondaryButton}
      variant="contained"
      color={props.color ?? "default"}
      aria-label={props["aria-label"]}
      disabled={props.disabled}
      onClick={() => props.onClick && props.onClick()}
      type={props.type ?? "button"}
      autoFocus={props.autoFocus}
    >
      {props.children}
    </Button>
  );
};

export const StrongGuidingButton: React.FunctionComponent<ButtonProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.strongGuidingButton}
      variant="outlined"
      color={props.color ?? "primary"}
      aria-label={props["aria-label"]}
      disabled={props.disabled}
      onClick={() => props.onClick && props.onClick()}
      type={props.type ?? "button"}
      autoFocus={props.autoFocus}
    >
      {props.children}
    </Button>
  );
};

export const MildGuidingButton: React.FunctionComponent<ButtonProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.mildGuidingButton}
      variant="outlined"
      color={props.color ?? "primary"}
      aria-label={props["aria-label"]}
      disabled={props.disabled}
      onClick={() => props.onClick && props.onClick()}
      type={props.type ?? "button"}
      autoFocus={props.autoFocus}
    >
      {props.children}
    </Button>
  );
};

export const SubtleButton: React.FunctionComponent<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.subtleButton}
      color={props.color ?? "primary"}
      aria-label={props["aria-label"]}
      disabled={props.disabled}
      onClick={() => props.onClick && props.onClick()}
      type={props.type ?? "button"}
      autoFocus={props.autoFocus}
    >
      {props.children}
    </Button>
  );
};
