import { AppError } from "../domain/AppError";
import { ApiRequest, ApiResponse, Payload } from "../domain/serverContract";

interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request);
  try {
    // may error if there is no body
    // const text = await response.text();

    response.parsedBody = await response.json();
  } catch (ex) {}

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "post", body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}
export async function makeRequest<TResponse extends Payload>(
  request: ApiRequest,
  path: string
) {
  const serverResponse = await post<ApiResponse>(origin + path, request);
  const body = serverResponse.parsedBody;
  if (body.errorMessage) {
    throw new AppError(body.errorMessage, body.errorCode, body.isExpectedError);
  }
  const response = serverResponse.parsedBody as ApiResponse;
  return { ...response.payload, timestamp: response.timestamp } as TResponse;
}
