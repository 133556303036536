import { Box } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { getLevelFromScore, Level } from "../../../domain/types";

const logoBlue = "#0055aa";
const logoYellow = "#FFCB05";

const vw = 400;
const vh = 60;
const achievedColor = logoBlue;
const highestAchievedColor = logoYellow;
const unachievedColor = "black"; //"#9a9ea1";
const rungRadius = 5;
const progressMarkRadius = rungRadius + 2;
const ladderLength = vw - 2 * rungRadius;
const ladderX = rungRadius;
const ladderY = progressMarkRadius;

export interface ProgressComponentProps {
  levels: Level[];
  score: number;
}
export const ProgressComponent = (props: ProgressComponentProps) => {
  const levels = props.levels;
  const score = props.score ?? 0;

  const [newKey, setNewKey] = useState(0);
  const [levelName, setLevelName] = useState("");
  const levelNameFromScore = getLevelFromScore(levels, score).name;
  const highestLevelScore = levels[levels.length - 1].score;
  const progressMarkerX = ladderX + (score / highestLevelScore) * ladderLength;
  const isLevelChanged = levelNameFromScore !== levelName;
  if (isLevelChanged) setNewKey(newKey + 1);
  const animatedLevelName = isLevelChanged ? null : (
    <Box>
      <svg
        key={newKey}
        viewBox={`0 0 ${vw} ${vh}`}
        width={vw}
        height={vh}
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1={ladderX}
          y1={vh - ladderY}
          x2={ladderX + ladderLength}
          y2={vh - ladderY}
          stroke="black"
          strokeWidth="5"
        />
        {(() => {
          // Generate a mark for each level
          const levelCount = levels.length;
          return levels.map((level, index) => {
            let color = unachievedColor;
            const achieved = score >= level.score;
            if (achieved) {
              if (index === levelCount - 1 || levels[index + 1].score > score) {
                color = highestAchievedColor;
              } else {
                color = achievedColor;
              }
            }
            return (
              <circle
                key={index}
                cx={
                  ladderX +
                  (levels[index].score / highestLevelScore) * ladderLength
                }
                cy={vh - ladderY}
                r={rungRadius}
                fill={color}
              />
            );
          });
        })()}
        <line // progress marker
          x1={progressMarkerX}
          x2={progressMarkerX}
          y1={vh - ladderY - progressMarkRadius}
          y2={vh - ladderY + progressMarkRadius}
          stroke={highestAchievedColor}
          strokeWidth="2"
        />
        <text
          x="200"
          y="40"
          textAnchor="middle"
          fontSize="40"
          fontWeight={700}
          fill={logoYellow}
        >
          {getLevelFromScore(levels, score).name}
          <animate attributeName="font-size" values="20;60;40" dur="1s" />
          <animate
            attributeName="fill"
            values={`${logoBlue};${logoYellow}`}
            dur="2s"
          />
        </text>
      </svg>
    </Box>
  );

  if (isLevelChanged) setLevelName(levelNameFromScore);
  return animatedLevelName;
};
