import { Box, Button, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import {
  PrimaryButton,
  StrongGuidingButton,
} from "../../../components/buttons";
import { ColumnContainer } from "../../../components/ColumnContainer";
import Hider from "../../../components/Hider";
import { openJustOkDialog } from "../../../components/JustOkDialog";
import {
  notifyErrorTransient,
  notifySuccessTransient,
} from "../../../components/NotificationManager";
import { RowContainer } from "../../../components/RowContainer";
import {
  Challenge,
  Chaser,
  ComputePlaySpec,
  ComputeProgress,
  createAllChallenges,
  solve,
  getOpChar,
} from "../../../domain/compute_types";
import { getLevelFromScore, Level } from "../../../domain/types";
import { useFreshState } from "../../../hooks/useFreshState";
import { max, pluralize, sum } from "../../../utilities";
import { shareProgress } from "../../play/components/ShareProgressComponent";
import {
  RulesContext,
  ShareProgressContext,
} from "../../play/components/TopAppBarComponent";
import {
  clearLocalStorageForGame,
  storeProgressLocally,
} from "../../play/localStorage";
import { ComputeRulesComponent } from "./ComputeRulesComponent";
import { GuessNumberInputComponent } from "./GuessNumberInputComponent";
import { KeypadComponent } from "../../play/components/KeypadComponent";
import { ProgressComponent } from "../../play/components/ProgressComponent";

const useStyles = makeStyles(() => {
  return createStyles({
    statusCell: {
      padding: "5px",
      backgroundColor: "lightgray",
      minWidth: "12rem",
      margin: "4px 0 4px 0",
    },
  });
});

const smile = "\u{1F600}";

export enum DispatchType {
  Add,
  Delete,
  Clear,
  Pop,
}

export interface Action {
  type: DispatchType;
  letter?: string;
}

export interface AppState {
  pendingAction: Action | undefined;
}

const initialAppState: AppState = { pendingAction: undefined };

function reducer(_state: AppState, action: Action): AppState {
  switch (action.type) {
    case DispatchType.Add:
      return { pendingAction: action };
    case DispatchType.Delete:
      return { pendingAction: action };
    case DispatchType.Clear:
      return { pendingAction: action };
    case DispatchType.Pop:
      return initialAppState;
    default:
      throw new Error();
  }
}

export interface ComputeGameComponentProps {
  gameRef: string;
  playSpec: ComputePlaySpec;
  progress: ComputeProgress;
  setCurrentProgress: (getProgress: () => ComputeProgress) => void;
}

export interface GameInfo {
  maxPoints: number;
  highestLevel: Level;
}

interface InteractiveGameState {
  gameRef: string;
  progress: ComputeProgress;
  currentGuess: string;
}

const makeStateFromProps = (
  props: ComputeGameComponentProps
): InteractiveGameState => {
  return {
    progress: props.progress ?? {
      chasers: [],
      elapsedSeconds: props.progress?.elapsedSeconds ?? 0,
    },
    gameRef: props.gameRef,
    currentGuess: "",
  };
};

export const ComputeGameComponent = (props: ComputeGameComponentProps) => {
  const [appState, dispatch] = useReducer(reducer, initialAppState);
  const [gameState, setGameState] = useFreshState(() =>
    makeStateFromProps(props)
  );
  const [firstTime, setFirstTime] = useState(true);
  // When starting is true, we display the start button
  const [starting, setStarting] = useState(true);
  const [finished, setFinished] = useFreshState(() => false);
  const [challengeCounter, setChallengeCounter] = useState(
    props.progress?.chasers?.length ?? 0
  );
  const [startingElapsedTime, setStartingElapsedTime] = useFreshState(
    () => props.progress?.elapsedSeconds ?? 0
  );
  const [elapsedIntervals, setElapsedIntervals] = useFreshState(() => 0);
  const elapsedTime = () => startingElapsedTime() + elapsedIntervals();

  // start time is now in case the game is mid-stream, in which case the user plays without clicking start
  const startTimeRef = useRef<number>(Date.now());
  const intervalIdRef = useRef<NodeJS.Timeout>(undefined);
  const [currentChallenge, setCurrentChallenge] = useState<Challenge>(
    undefined
  );
  const classes = useStyles();

  useEffect(() => {
    const f =
      (props.playSpec.timeLimit > 0 &&
        elapsedTime() >= props.playSpec.timeLimit) ||
      props.playSpec.challengeCount === gameState().progress.chasers.length;
    if (f != finished()) setFinished(f);
  });

  props.setCurrentProgress(() => gameState().progress);
  const [gameInfo] = useState(
    (): GameInfo => {
      return {
        maxPoints: props.playSpec.challengeCount,
        highestLevel: props.playSpec.levels[props.playSpec.levels.length - 1],
      };
    }
  );

  // The first time through compile the operand ranges and create a list of challenges.
  // The challenges will then be selected at random.
  const challengesRef = useRef(createAllChallenges(props.playSpec));

  // Get a new challenge each time the challengeCounter changes.
  useEffect(() => {
    const i = Math.floor(
      Math.random() * Math.floor(challengesRef.current.length)
    );
    setCurrentChallenge(challengesRef.current[i]);
    if (props.playSpec.challengeCount <= challengesRef.current.length) {
      // Remove played challenges as long as there are more challenges in the hopper than
      // challenges per game. This comparision will not change during each game.
      challengesRef.current.splice(i, 1);
    }
  }, [challengeCounter, starting]);

  const displayRules = () => {
    openJustOkDialog({
      content: (
        <ComputeRulesComponent gameInfo={gameInfo} playSpec={props.playSpec} />
      ),
      title: "Rules",
    });
  };

  useEffect(() => {
    if (challengeCounter === 0) displayRules();
  }, []);

  useContext(ShareProgressContext)(() =>
    shareProgress(gameState().gameRef, gameState().progress)
  );

  useContext(RulesContext)(displayRules);

  useEffect(() => {
    // Save state to local storage
    // Only when the correct answers have changed
    // Don't write the first time, because we already either read or wrote the state
    if (firstTime) {
      setFirstTime(false);
      return;
    }
    storeProgressLocally(
      gameState().gameRef,
      (data) => {
        data.progress = gameState().progress;
        data.unmodified = false;
      },
      false // means not updated shared progress
    );
  }, [gameState().progress]);

  const [score, setScore] = useState(0);
  useEffect(() => {
    // Update score when answers changes
    const score = sum(
      gameState().progress.chasers.map((chaser) =>
        solve(chaser.challenge) === chaser.answer ? 1 : 0
      )
    );
    setScore(score);
  }, [gameState().progress.chasers]);

  const [level, setLevel] = useState(props.playSpec.levels[0]);

  const submitHandler = () => {
    if (gameState().currentGuess === "") return;
    if (finished()) {
      notifySuccessTransient(`The game is finished.`);
      return;
    }
    const answer = Number(gameState().currentGuess);
    const isCorrect = answer === solve(currentChallenge);
    if (isCorrect) {
      notifySuccessTransient(`Correct! ${smile}`);
    } else {
      notifyErrorTransient(`${gameState().currentGuess} is not correct`);
      if (props.playSpec.mustSolve) {
        dispatch({ type: DispatchType.Clear });
        return;
      }
    }
    const chaser: Chaser = {
      answer,
      challenge: currentChallenge,
      skipped: false,
    };
    const newGameState = {
      ...gameState(),
      currentGuess: "",
      progress: {
        chasers: [...gameState().progress.chasers, chaser],
        elapsedSeconds: elapsedTime(),
      },
    };
    setGameState(newGameState);
    dispatch({ type: DispatchType.Clear });

    const nextLevel = getLevelFromScore(props.playSpec.levels, score + 1);
    const isNewLevel = nextLevel.score !== level.score;
    if (isNewLevel) {
      // openJustOkDialog({content: achievementLevelElement, title: "Congrats!"})
      //setShowAchievementLevel(true); //********************************************
    }

    setLevel(nextLevel);
    setChallengeCounter(challengeCounter + 1);
  };

  const keyUpHandler = (event: KeyboardEvent) => {
    if (event.keyCode === 13) {
      //Enter key starts, but once started it submits answers.
      if (starting) {
        startResume();
        return;
      }
      submitHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", keyUpHandler, false);
    return () => {
      document.removeEventListener("keyup", keyUpHandler, false);
    };
  });

  const startResume = () => {
    startTimeRef.current = Date.now();
    setStarting(false);
    intervalIdRef.current = setInterval(() => {
      if (finished()) return;
      const elapsedSeconds = Math.round(
        (Date.now() - startTimeRef.current) / 1000
      );
      setElapsedIntervals(elapsedSeconds);
      const gs: InteractiveGameState = {
        currentGuess: gameState().currentGuess,
        gameRef: gameState().gameRef,
        progress: {
          chasers: gameState().progress.chasers,
          elapsedSeconds: elapsedTime(),
        },
      };
      setGameState(gs);
    }, 1000);
  };

  return (
    <RowContainer center>
      <ColumnContainer center>
        <Typography variant="subtitle1">{props.playSpec.title}</Typography>
        <Typography variant="subtitle2">{props.playSpec.subtitle}</Typography>
        <Box m={0.2} />
        {starting && !finished() && (
          <StrongGuidingButton onClick={startResume}>
            {challengeCounter === 0 && elapsedTime() === 0
              ? "Click to Start"
              : "Click to Resume"}
          </StrongGuidingButton>
        )}
        <Hider hidden={!finished()}>
          <Typography variant="h4" color="secondary">
            DONE!
          </Typography>
        </Hider>
        {!starting && (
          <ColumnContainer center>
            <Box m={0.2} />
            <Hider unmount hidden={finished() || currentChallenge == null}>
              <ColumnContainer center>
                <RowContainer center>
                  <Typography variant="h3">{currentChallenge?.lhs}</Typography>
                  <Typography variant="h3">
                    {getOpChar(currentChallenge?.op)}
                  </Typography>
                  <Typography variant="h3">{currentChallenge?.rhs}</Typography>
                </RowContainer>

                <GuessNumberInputComponent
                  appState={appState}
                  dispatchPopAction={() => {
                    dispatch({ type: DispatchType.Pop });
                  }}
                  onChange={(guess: string) => {
                    setGameState({
                      ...gameState(),
                      currentGuess: guess,
                    });
                  }}
                />

                <Box m={1} />
                <RowContainer center>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      dispatch({ type: DispatchType.Delete });
                    }}
                  >
                    Delete
                  </Button>
                  <Box m={1} />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={submitHandler}
                  >
                    Submit
                  </Button>
                </RowContainer>
                <Box m={1} />
                <RowContainer center>
                  <KeypadComponent
                    onClick={(letter: string) => {
                      dispatch({ type: DispatchType.Add, letter });
                    }}
                  />
                </RowContainer>
              </ColumnContainer>
            </Hider>
          </ColumnContainer>
        )}
        <RowContainer center>
          <ColumnContainer className={classes.statusCell} center>
            <div>{props.playSpec.challengeCount - challengeCounter}</div>
            <div>Challenges Remaining</div>
          </ColumnContainer>
          {(props.playSpec.timeLimit ?? 0) === 0 && (
            <ColumnContainer className={classes.statusCell} center>
              <div>{elapsedTime()}</div>
              <div>Elapsed Time</div>
            </ColumnContainer>
          )}
          {(props.playSpec.timeLimit ?? 0) > 0 && (
            <ColumnContainer className={classes.statusCell} center>
              <div>
                {max([0, props.playSpec.timeLimit - elapsedTime()])}
                {" sec"}
              </div>
              <div>Time Remaining</div>
            </ColumnContainer>
          )}
        </RowContainer>
        <ProgressComponent levels={props.playSpec.levels} score={score} />
        <div>
          You have {pluralize(score, "correct answer")} for{" "}
          {pluralize(score, "point")}
        </div>
        <RowContainer center>
          {gameState()
            .progress.chasers.filter(
              (chaser) => solve(chaser.challenge) === chaser.answer
            )
            .map((chaser, i) => {
              return (
                <div style={{ margin: "0 1rem 0 0" }} key={i}>
                  {chaser.challenge.lhs}
                  {getOpChar(chaser.challenge.op)}
                  {chaser.challenge.rhs}={chaser.answer}
                </div>
              );
            })}
        </RowContainer>
        <Hider unmount hidden={props.playSpec.mustSolve}>
          <ColumnContainer center>
            <div>Wrong answers: {challengeCounter - score}</div>
            <RowContainer center>
              {gameState()
                .progress.chasers.filter(
                  (chaser) => solve(chaser.challenge) !== chaser.answer
                )
                .map((chaser, i) => {
                  return (
                    <div style={{ margin: "0 1rem 0 0" }} key={i}>
                      {chaser.challenge.lhs}
                      {getOpChar(chaser.challenge.op)}
                      {chaser.challenge.rhs}={chaser.answer}
                    </div>
                  );
                })}
            </RowContainer>
          </ColumnContainer>
        </Hider>
        <PrimaryButton
          onClick={() => {
            clearLocalStorageForGame();
            if (intervalIdRef.current) clearInterval(intervalIdRef.current);
            intervalIdRef.current = undefined;
            setGameState({
              ...makeStateFromProps(props),
              progress: { chasers: [], elapsedSeconds: 0 },
            });
            setStarting(true);
            setFinished(false);
            setChallengeCounter(0);
            setCurrentChallenge(undefined);
            setStartingElapsedTime(0);
            setElapsedIntervals(0);
          }}
        >
          Play Again
        </PrimaryButton>
      </ColumnContainer>
    </RowContainer>
  );
};
