import { Box, Button, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { useContext, useEffect, useReducer, useState } from "react";
import { openJustOkDialog } from "../../../components/JustOkDialog";
import {
  notifyErrorTransient,
  notifySuccess,
  notifySuccessTransient,
} from "../../../components/NotificationManager";
import { getLevelFromScore, Level } from "../../../domain/types";
import { shareProgress } from "../../play/components/ShareProgressComponent";

import { storeProgressLocally } from "../../play/localStorage";
import { SpellPlaySpec, SpellProgress } from "../spell_types";
import { GuessInputComponent } from "./GuessInputComponent";
import { LettersComponent } from "./LettersComponent";
import { ProgressComponent } from "./ProgressComponent";
import { SpellRulesComponent } from "./SpellRulesComponent";
import {
  makeSeparatedStringFromList,
  min,
  sum,
  shuffle,
} from "../../../utilities";
import {
  ShareProgressContext,
  RulesContext,
} from "../../play/components/TopAppBarComponent";

const smile = "\u{1F600}";

export enum DispatchType {
  Add,
  Delete,
  Clear,
  Pop,
}

export interface Action {
  type: DispatchType;
  letter?: string;
}

export interface AppState {
  pendingAction: Action | undefined;
}

const initialAppState: AppState = { pendingAction: undefined };

function reducer(_state: AppState, action: Action): AppState {
  switch (action.type) {
    case DispatchType.Add:
      return { pendingAction: action };
    case DispatchType.Delete:
      return { pendingAction: action };
    case DispatchType.Clear:
      return { pendingAction: action };
    case DispatchType.Pop:
      return initialAppState;
    default:
      throw new Error();
  }
}

export interface SpellGameComponentProps {
  gameRef: string;
  playSpec: SpellPlaySpec;
  progress: SpellProgress;
  setCurrentProgress: (getProgress: () => SpellProgress) => void;
}

export interface GameInfo {
  minLength: number;
  maxPoints: number;
  highestLevel: Level;
  canReuseLetters: boolean;
  pangramCount: number;
}

interface InteractiveGameState {
  gameRef: string;
  letters: Array<string>;
  foundWords: string[];
  currentGuess: string;
}

const makeStateFromProps = (
  props: SpellGameComponentProps
): InteractiveGameState => {
  return {
    letters: [
      ...(props.playSpec.requiredLetters ?? []),
      ...(props.playSpec.optionalLetters ?? []),
    ],
    foundWords: props.progress ?? ([] as SpellProgress),
    currentGuess: "",
    gameRef: props.gameRef,
  };
};

export const getCanReuseLetters = (playSpec: SpellPlaySpec) => {
  return (
    undefined !==
    playSpec.answers.find((answer) => {
      const letters = Array.from(answer.word);
      const letterCount = letters.length;
      // Compare first letter to all following
      // Compare second letter to all following
      // ...stop at next to last letter
      for (let i = 0; i < letterCount - 1; i++) {
        for (let j = i + 1; j < letterCount; j++) {
          if (letters[i] === letters[j]) return true;
        }
      }
      return false;
    })
  );
};

export const getPangramCount = (playSpec: SpellPlaySpec) => {
  const answerLetters = [
    ...(playSpec.requiredLetters ?? []),
    ...(playSpec.optionalLetters ?? []),
  ];
  const answerLetterCount = answerLetters.length;
  const pangrams = playSpec.answers.filter((answer) => {
    if (answer.word.length < answerLetterCount) return false; // too short
    for (let i = 0; i < answerLetterCount; i++) {
      if (!answer.word.includes(answerLetters[i])) return false;
    }
    return true;
  });
  return pangrams?.length ?? 0;
};

export const getMinLength = (playSpec: SpellPlaySpec) => {
  return min(playSpec.answers.map((answer) => answer.word.length));
};

export const SpellGameComponent = (props: SpellGameComponentProps) => {
  const [appState, dispatch] = useReducer(reducer, initialAppState);
  const [gameState, setGameState] = useState(makeStateFromProps(props));
  const [firstTime, setFirstTime] = useState(true);
  props.setCurrentProgress(() => gameState.foundWords);
  const [gameInfo] = useState(
    (): GameInfo => {
      return {
        minLength: getMinLength(props.playSpec),
        maxPoints: sum(props.playSpec.answers.map((answer) => answer.score)),
        highestLevel: props.playSpec.levels[props.playSpec.levels.length - 1],
        pangramCount: getPangramCount(props.playSpec),
        canReuseLetters: getCanReuseLetters(props.playSpec),
      };
    }
  );

  const displayRules = () => {
    openJustOkDialog({
      content: (
        <SpellRulesComponent gameInfo={gameInfo} playSpec={props.playSpec} />
      ),
      title: "Rules",
    });
  };

  useContext(ShareProgressContext)(() =>
    shareProgress(gameState.gameRef, gameState.foundWords)
  );

  useContext(RulesContext)(displayRules);

  useEffect(() => {
    // Save state to local storage
    // Only when the correct answers have changed
    // Don't write the first time, because we already either read or wrote the state
    if (firstTime) {
      setFirstTime(false);
      return;
    }
    storeProgressLocally(
      gameState.gameRef,
      (data) => {
        data.progress = gameState.foundWords;
        data.unmodified = false;
      },
      false // means not updated shared progress
    );
  }, [gameState.foundWords]);

  const [score, setScore] = useState(0);
  useEffect(() => {
    // Update score when answers change
    const answers = props.playSpec.answers;
    const score = sum(
      gameState.foundWords.map((answerWord) => {
        const found = answers.find((answer) => answer.word === answerWord);
        return found?.score ?? 0;
      })
    );
    setScore(score);
    if (score === 0) displayRules();
  }, [gameState.foundWords]);

  const [level, setLevel] = useState(props.playSpec.levels[0]);

  const submitHandler = () => {
    if (gameState.currentGuess === "") return;
    if (props.playSpec.answers.length === gameState.foundWords.length) {
      notifySuccess(
        `You already found all ${props.playSpec.answers.length} words!`
      );
      return;
    }

    if (gameState.foundWords.find((word) => word === gameState.currentGuess)) {
      notifyErrorTransient(`${gameState.currentGuess} was already found`);
      return;
    }

    let answer = props.playSpec.answers.find(
      (answer) => answer.word === gameState.currentGuess
    );
    const answerWord = answer?.word;
    const foundWords =
      answerWord === undefined
        ? gameState.foundWords
        : [...gameState.foundWords, answerWord];
    if (answerWord === undefined) {
      if (gameState.currentGuess.length < gameInfo.minLength) {
        notifyErrorTransient(`${gameState.currentGuess} is too short`);
        return;
      }
      if (props.playSpec.requiredLetters?.length > 0) {
        // Find which letters are missing
        const missingLetters = [
          ...(props.playSpec.requiredLetters ?? []),
        ].filter(
          (requiredLetter) =>
            gameState.currentGuess.includes(requiredLetter) === false
        );
        if (missingLetters.length > 0) {
          if (missingLetters.length === 1) {
            notifyErrorTransient(
              `${gameState.currentGuess} is missing one required letter: ${missingLetters[0]}`
            );
            return;
          }
          notifyErrorTransient(
            `${
              gameState.currentGuess
            } is missing these required letters: ${makeSeparatedStringFromList(
              missingLetters
            )}`
          );
          return;
        }
      }
      notifyErrorTransient(
        `${gameState.currentGuess} is not one of the answers`
      );
      return;
    }

    const newGameState = {
      ...gameState,
      currentGuess: "",
      foundWords,
    };
    setGameState(newGameState);
    dispatch({ type: DispatchType.Clear });

    const nextLevel = getLevelFromScore(
      props.playSpec.levels,
      score + answer.score
    );
    const isNewLevel = nextLevel.score !== level.score;
    if (isNewLevel) {
      // openJustOkDialog({content: achievementLevelElement, title: "Congrats!"})
      //setShowAchievementLevel(true); //********************************************
    }
    notifySuccessTransient(
      `${answer.word} is worth ${answer.score} points ${smile}`
    );
    setLevel(nextLevel);
  };

  const keyUpHandler = (event: KeyboardEvent) => {
    if (event.keyCode === 13) {
      //Enter key
      submitHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", keyUpHandler, false);
    return () => {
      document.removeEventListener("keyup", keyUpHandler, false);
    };
  });

  const progressComponent = (
    <ProgressComponent
      foundWords={gameState.foundWords}
      answers={props.playSpec.answers}
      gameAnswerCount={props.playSpec.answers.length}
      levels={props.playSpec.levels}
      score={score}
    />
  );

  return (
    <Grid container direction="row">
      <Grid container direction="column" alignItems="center">
        <Typography variant="subtitle1">{props.playSpec.title}</Typography>
        <Typography variant="subtitle2">{props.playSpec.subtitle}</Typography>
        <Box m={0.2} />
        <GuessInputComponent
          appState={appState}
          dispatchPopAction={() => {
            dispatch({ type: DispatchType.Pop });
          }}
          requiredLetters={props.playSpec.requiredLetters}
          optionalLetters={props.playSpec.optionalLetters}
          onChange={(guess: string) => {
            setGameState({
              ...gameState,
              currentGuess: guess,
            });
          }}
        />

        <Box m={1} />
        <Grid container justify="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              dispatch({ type: DispatchType.Delete });
            }}
          >
            Delete
          </Button>
          <Box m={1} />
          <Button variant="outlined" color="primary" onClick={submitHandler}>
            Submit
          </Button>
        </Grid>
        <Box m={1} />
        <Grid container justify="center">
          <LettersComponent
            letters={gameState.letters}
            requiredLetters={props.playSpec.requiredLetters}
            onClick={(letter: string) => {
              dispatch({ type: DispatchType.Add, letter });
            }}
          />
        </Grid>
        <Box m={1} />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            const newGameState = {
              ...gameState,
              letters: shuffle(gameState.letters),
            };
            setGameState(newGameState);
          }}
        >
          Shuffle
        </Button>
      </Grid>
      {progressComponent}
    </Grid>
  );
};
