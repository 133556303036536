import { Box } from "@material-ui/core";
import * as React from "react";
import { InputButtonComponent } from "../../play/components/InputButtonComponent";

export interface LettersComponentProps {
  letters: Array<string>;
  requiredLetters: string;
  onClick: (letter: string) => void;
}
export const LettersComponent = (props: LettersComponentProps) => {
  const { letters, requiredLetters, onClick } = props;

  return (
    <>
      {letters.map((letter, index) => {
        return (
          <Box key={index} m={1}>
            <InputButtonComponent
              onClick={() => {
                onClick(letter);
              }}
              letter={letter}
              isRequired={
                !![...(requiredLetters ?? [])].find(
                  (requiredLetter) => requiredLetter === letter
                )
              }
            />
          </Box>
        );
      })}
    </>
  );
};
