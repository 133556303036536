import { Box, Container } from "@material-ui/core";
import * as React from "react";
import { TwistPlaySpec } from "../../../domain/twist_types";
import { pluralize } from "../../../utilities";
import { GameInfo } from "./TwistGameComponent";

export interface TwistRulesComponentProps {
  playSpec: TwistPlaySpec;
  gameInfo: GameInfo;
}
export const TwistRulesComponent = (props: TwistRulesComponentProps) => {
  const { playSpec, gameInfo } = props;
  const rulesPrologue = playSpec.rulesPrologue;
  const rulesPrologueComponent = !rulesPrologue ? null : (
    <Container>{rulesPrologue}</Container>
  );
  const challengeCount = playSpec.challenges?.length ?? 0;
  const extraLetterChallengeCount = playSpec.challenges.filter(
    (challenge) =>
      challenge.fillin.length >
      challenge.fixed.split("").filter((letter) => letter === "*").length
  ).length;
  return (
    <Box>
      {rulesPrologueComponent}
      <Box m={1} />
      <Container>
        <p>The following rules apply to this game:</p>
        <ul style={{ marginTop: 0 }}>
          <li>
            Fill in the missing letters in the top row, using the letters in the
            bottom row.
          </li>
          <li>
            The solutions are{playSpec.caseSensitive ? " " : " not "}
            case-sensitive.
          </li>
          <li>
            {`The game has ${pluralize(
              challengeCount,
              "challenge"
            )}  worth a total of ${pluralize(challengeCount, "point")}.`}
            {gameInfo.highestLevel.score >= gameInfo.maxPoints
              ? ""
              : " You don't need to solve all of the challenges to achieve the top level."}
          </li>
          <li>
            Click a letter in the bottom row to use that letter in the top row.
            Each letter in the bottom row can be used no more than once.
          </li>
          {extraLetterChallengeCount === challengeCount && (
            <li>
              All challenges have extra letters, meaning some letters are not
              used in the solution.
            </li>
          )}
          {extraLetterChallengeCount > 0 &&
            extraLetterChallengeCount < challengeCount && (
              <li>
                {pluralize(extraLetterChallengeCount, "challenge ")}
                {extraLetterChallengeCount === 1 ? "has" : "have"} extra
                letters, meaning some letters are not used in the solution.
              </li>
            )}
          <li>The game is over after you have solved all of the challenges.</li>
        </ul>
      </Container>
      <Box m={1} />
      <Container>
        The game levels and the number of points needed to reach each level are:{" "}
        {props.playSpec.levels.map((level, index) => (
          <span key={index}>
            {index > 0 ? ", " : ""}
            <b>{level.name}</b> {level.score}
          </span>
        ))}
        .
      </Container>
      <Box m={2} />
    </Box>
  );
};
