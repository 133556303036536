import { Divider } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { SecondaryButton } from "../../../components/buttons";
import { ColumnContainer } from "../../../components/ColumnContainer";
import { RowContainer } from "../../../components/RowContainer";
import { Challenge } from "../../../domain/twist_types";
import { GuessDisplayComponent } from "./GuessDisplayComponent";
import { GuessInputButtonComponent } from "./GuessInputButtonComponent";

const useStyles = makeStyles((theme) => {
  return createStyles({
    letterContainers: {
      flexWrap: "wrap",
    },
    cursor: {
      color: theme.palette.primary.main,
      fontSize: "200%",
      animation: "$blinker 1.5s linear infinite",
    },
    "@keyframes blinker": {
      "50%": {
        opacity: 0,
      },
    },
  });
});

const Cursor = (props: { visible: boolean }) => {
  return (
    <div
      className={useStyles().cursor}
      style={{ display: props.visible ? "block" : "none" }}
    >
      |
    </div>
  );
};

export interface Slot {
  // fixedLetter is undefined if slot is mutable
  fixedLetter: string;
  token: Token; // current content; disregard if not mutable
}

export interface Token {
  // index is the token's position in the fill-in array
  index: number;
  isAvailable: boolean;
  letter: string;
}

export interface GuessInputComponentProps {
  onSubmit: (guess: string) => void;
  challenge: Challenge;
  caseSensitive: boolean;
}
export const GuessInputComponent = (props: GuessInputComponentProps) => {
  const { challenge } = props;

  const firstMutableSlotIndexRef = useRef<number>(-1);
  const lastMutableSlotIndexRef = useRef<number>(-1);
  const slotCursorPosRef = useRef<number>(-1);

  const slotsRef = useRef<Slot[]>(null);
  const tokensRef = useRef<Token[]>(null);
  const challengeRef = useRef<Challenge>(null);
  if (challenge !== challengeRef.current) {
    // There's a new challenge. Bust the refs!
    slotsRef.current = null;
    tokensRef.current = null;
    firstMutableSlotIndexRef.current = [...challenge.fixed].findIndex(
      (letter) => letter === "*"
    );
    lastMutableSlotIndexRef.current =
      challenge.solution.length -
      1 -
      [...challenge.fixed].reverse().findIndex((letter) => letter === "*");
    slotCursorPosRef.current = firstMutableSlotIndexRef.current;
    challengeRef.current = challenge;
  }
  const firstMutableSlotIndex = firstMutableSlotIndexRef.current;
  const lastMutableSlotIndex = lastMutableSlotIndexRef.current;
  const slotCursorPos = slotCursorPosRef.current;

  const initSlots = (): Slot[] =>
    [...challenge.fixed].map((letter, index) =>
      letter === "*"
        ? { fixedLetter: undefined, token: undefined }
        : { fixedLetter: letter, token: undefined }
    );
  const getSlots = () => {
    if (slotsRef.current === null) {
      slotsRef.current = initSlots();
    }
    return slotsRef.current;
  };
  const slots = getSlots();

  const initTokens = (): Token[] =>
    [...challenge.fillin].map((letter, index) => {
      return { isAvailable: true, letter, index };
    });
  const getTokens = () => {
    if (tokensRef.current === null) {
      tokensRef.current = initTokens();
    }
    return tokensRef.current;
  };
  const tokens = getTokens();

  // currentGuess will be too short unless mutable slots are filled
  const computeGuess = () =>
    slots
      .map((slot) =>
        slot.fixedLetter ? slot.fixedLetter : slot.token?.letter ?? ""
      )
      .join("");
  const [currentGuess, setCurrentGuess] = useState<string>(computeGuess);

  useEffect(() => {
    setCurrentGuess(computeGuess());
  }, [challenge.solution]);

  // getNextCursorPosition gets next cur pos to right
  const getNextCursorPosition = () => {
    let nextCursorPosition = slots.findIndex(
      (slot, index) => slot.fixedLetter == null && index > slotCursorPos
    );
    return nextCursorPosition >= 0
      ? nextCursorPosition
      : lastMutableSlotIndex + 1;
  };

  // getPrevCursorPosition gets next cur pos to left -- or returns same pos if nothing to left
  const getPrevCursorPosition = () => {
    for (let i = slotCursorPos - 1; i >= firstMutableSlotIndex; i--) {
      if (slots[i].fixedLetter == null) return i;
    }
    return slotCursorPos;
  };

  const insertTokenAtCursor = (token: Token) => {
    if (slotCursorPos > lastMutableSlotIndex) return;
    if (!token.isAvailable) throw new Error("token not available");
    const slot = slots[slotCursorPos];
    if (slot.token != null) throw new Error("slot already filled");
    token.isAvailable = false;
    slot.token = token;
    const nextCursorPosition = getNextCursorPosition();
    if (nextCursorPosition !== slotCursorPos) {
      slotCursorPosRef.current = nextCursorPosition;
    }
    setCurrentGuess(computeGuess());
  };

  const deleteTokenLeftOfCursor = () => {
    if (slotCursorPos === firstMutableSlotIndex) return;
    const nextCursorPosition = getPrevCursorPosition();
    const deleteSlot = slots[nextCursorPosition];
    const deleteToken = deleteSlot.token;
    deleteToken.isAvailable = true;
    deleteSlot.token = undefined;
    slotCursorPosRef.current = nextCursorPosition;
    setCurrentGuess(computeGuess());
  };

  const keyDownHandler = (event: KeyboardEvent) => {
    switch (event.key) {
      case "Backspace":
      case "Delete":
        deleteTokenLeftOfCursor();
        break;
      case "Enter":
        props.onSubmit(computeGuess());
        break;
      default:
        let token: Token;
        if (props.caseSensitive) {
          token = tokens.find(
            (token) => token.isAvailable && token.letter === event.key
          );
        } else {
          token = tokens.find(
            (token) =>
              token.isAvailable &&
              token.letter.toLowerCase() === event.key.toLowerCase()
          );
        }
        if (token) insertTokenAtCursor(token);
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler, false);
    return () => {
      document.removeEventListener("keydown", keyDownHandler, false);
    };
  });

  return (
    <ColumnContainer center>
      <RowContainer center>
        {slots.map((slot, i) => {
          return (
            <div
              key={i}
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Cursor visible={slotCursorPos === i} />
              <GuessDisplayComponent
                isMutable={slot.fixedLetter == null}
                letter={slot.fixedLetter || slot.token?.letter}
              />
              <Cursor
                visible={
                  slotCursorPos === slots.length && i === slotCursorPos - 1
                }
              />
            </div>
          );
        })}
      </RowContainer>
      <RowContainer center>{challenge.clue}</RowContainer>
      <Divider
        variant="middle"
        style={{
          margin: "0.5rem 0 0.5rem 0",
          height: "2px",
          minWidth: "10rem",
        }}
      />
      <RowContainer center>
        {tokens.map((token, i) => {
          return (
            <GuessInputButtonComponent
              key={i}
              isAvailable={token.isAvailable}
              letter={token.letter}
              onClick={() => {
                token.isAvailable && insertTokenAtCursor(token);
              }}
            />
          );
        })}
      </RowContainer>
      <RowContainer center>
        <SecondaryButton
          onClick={() => {
            deleteTokenLeftOfCursor();
          }}
        >
          Delete
        </SecondaryButton>
        <SecondaryButton
          onClick={() => {
            props.onSubmit(computeGuess());
          }}
        >
          Submit
        </SecondaryButton>
      </RowContainer>
    </ColumnContainer>
  );
};
