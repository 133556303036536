import { Box, Paper } from "@material-ui/core";
import * as React from "react";

export const PlayPrivacy = () => {
  return (
    <Box m={1}>
      <Paper>
        <Box m={1}>
          This privacy policy describes what we do with your data when you play
          this game. It is effective as of 13 August 2020. Questions about this
          policy should be emailed to privacy @ cortexplay.com.
          <ul>
            <li>
              We do not ask for or store any information about who you are, such
              as your name, age, email address, gender. This game is designed to
              be played without a user account, specifically to avoid collecting
              personally-identifiable information.
            </li>
            <li>
              We do collect information that helps us provide a reliable
              service, such as the type of device and browser you use to play
              the game, and the IP address from which you access the game.
            </li>
            <li>
              We do collect information that helps us improve the quality of the
              game, such as which features you use when you play.
            </li>
            <li>
              We do store information on your computer about the games you play.
              We do that for two reasons:
              <ul>
                <li>
                  No user has an account, so we cannot store game progress on
                  our server in a way that is associated with a user. We store
                  game progress on your computer so that you can continue a game
                  at a later time without losing your progress. You can store
                  your progress on our servers, but that is not stored with any
                  information about you.
                </li>
                <li>Storing some data locally improves performance.</li>
              </ul>
            </li>
          </ul>
        </Box>
      </Paper>
    </Box>
  );
};
