import { Box } from "@material-ui/core";
import * as React from "react";
import { InputButtonComponent } from "./InputButtonComponent";
import { ColumnContainer } from "../../../components/ColumnContainer";
import { RowContainer } from "../../../components/RowContainer";

export interface KeypadComponentProps {
  onClick: (digit: string) => void;
}
export const KeypadComponent = (props: KeypadComponentProps) => {
  const { onClick } = props;
  return (
    <ColumnContainer center>
      <RowContainer center>
        {[7, 8, 9].map((digit) => {
          return (
            <Box key={digit} m={1}>
              <InputButtonComponent
                onClick={() => {
                  onClick(digit.toString());
                }}
                letter={digit.toString()}
                isRequired={false}
              />
            </Box>
          );
        })}
      </RowContainer>
      <RowContainer center>
        {[4, 5, 6].map((digit) => {
          return (
            <Box key={digit} m={1}>
              <InputButtonComponent
                onClick={() => {
                  onClick(digit.toString());
                }}
                letter={digit.toString()}
                isRequired={false}
              />
            </Box>
          );
        })}
      </RowContainer>
      <RowContainer center>
        {[1, 2, 3].map((digit) => {
          return (
            <Box key={digit} m={1}>
              <InputButtonComponent
                onClick={() => {
                  onClick(digit.toString());
                }}
                letter={digit.toString()}
                isRequired={false}
              />
            </Box>
          );
        })}
      </RowContainer>
      <RowContainer center>
        {[0].map((digit) => {
          return (
            <Box key={digit} m={1}>
              <InputButtonComponent
                onClick={() => {
                  onClick(digit.toString());
                }}
                letter={digit.toString()}
                isRequired={false}
              />
            </Box>
          );
        })}
      </RowContainer>
    </ColumnContainer>
  );
};
