export class AppError extends Error {
  constructor(
    message: string,
    public code: string,
    public isExpected: boolean
  ) {
    super(message);
    this.name = "AppError";
  }
}

export enum AppErrorCode {
  ErrAbstractNotCurrent,
}
