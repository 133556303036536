import { Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
const useStyles = makeStyles((theme) => {
  return createStyles({
    cell: {
      margin: "4px 4px 4px 4px",
      textTransform: "none",
      height: "2rem",
    },
    letter: {
      height: theme.typography.button.lineHeight + "rem",
    },
  });
});
export interface GuessDisplayComponentProps {
  letter: string;
  isMutable: boolean;
}

const Div = React.forwardRef((props, ref) => (
  <div ref={ref as any}>{props.children}</div>
));

// InputButtonComponent presents a button in a custom keyboard.
export const GuessDisplayComponent = (props: GuessDisplayComponentProps) => {
  const classes = useStyles();
  const { isMutable, letter } = props;
  return (
    <Button
      className={classes.cell}
      variant={letter == null ? "outlined" : "contained"}
      color={isMutable ? (letter == null ? "primary" : "secondary") : "default"}
      disableRipple
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>{letter ?? ""}</div>
      </div>
    </Button>
  );
};
