import {
  AppBar,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Menu as MenuIcon, ReplayOutlined } from "@material-ui/icons";
import * as React from "react";
import { useClientRect } from "../../../measure";
import { clearLocalStorageForGame, clearLocalStorage } from "../localStorage";
import { ToolbarTitle } from "../../../components/ToolbarTitle";
import { openConfirmationDialog } from "../../../components/ConfirmationDialog";
import {
  getGameTypeLabel,
  gameTypeHasAnswers,
  gameTypeUsesMenuRestart,
} from "../../../domain/types";
import { getGameType } from "../globalPlayData";

export interface TopAppBarProps {
  answers: () => void;
}

export const ShareProgressContext = React.createContext(null);
export const RulesContext = React.createContext(null);
export const RestartContext = React.createContext(null);

let shareProgressHandler = () => {};
const setShareProgressHandler = (handler: () => {}) => {
  shareProgressHandler = handler;
};

let rulesHandler = () => {};
const setRulesHandler = (handler: () => {}) => {
  rulesHandler = handler;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  });
});

export const TopAppBarComponent: React.FunctionComponent<TopAppBarProps> = (
  props
) => {
  const [rect, ref] = useClientRect();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuOpen = Boolean(anchorEl);
  return (
    <>
      <AppBar position="static" ref={ref}>
        <Toolbar>
          <div>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <Menu open={menuOpen} onClose={handleClose} anchorEl={anchorEl}>
            {gameTypeUsesMenuRestart(getGameType()) && (
              <MenuItem
                onClick={async () => {
                  handleClose();
                  const isOkay = await openConfirmationDialog({
                    content: (
                      <Container>
                        If you restart the game, all of your answers for this
                        game will be deleted from this device. Your shared
                        progress will not be deleted. nor will your answers for
                        other games.
                      </Container>
                    ),
                    title: "Restart Game",
                    okayText: "Restart Game",
                  });
                  if (isOkay) {
                    clearLocalStorageForGame();
                    location.reload();
                  }
                }}
              >
                Restart
              </MenuItem>
            )}
            <MenuItem
              onClick={async () => {
                handleClose();
                const isOkay = await openConfirmationDialog({
                  content: (
                    <Container>
                      If you reset the game, all of your answers for all of your
                      Cortex games will be deleted from this device. Your shared
                      progress will not be deleted. Only use this if you are
                      having trouble getting games to work.
                    </Container>
                  ),
                  title: "title",
                  okayText: "Factory Reset",
                });
                if (isOkay) {
                  clearLocalStorage();
                  location.reload();
                }
              }}
            >
              Factory Reset
            </MenuItem>
          </Menu>
          <ToolbarTitle>{getGameTypeLabel(getGameType())}</ToolbarTitle>
          {gameTypeHasAnswers(getGameType()) && (
            <Button color="inherit" onClick={() => props.answers()}>
              Answers
            </Button>
          )}
          <Button color="inherit" onClick={() => shareProgressHandler()}>
            Share
          </Button>
          <Button color="inherit" onClick={() => rulesHandler()}>
            Rules
          </Button>
        </Toolbar>
      </AppBar>
      <ShareProgressContext.Provider value={setShareProgressHandler}>
        <RulesContext.Provider value={setRulesHandler}>
          <div
            id="topappbar"
            style={{
              overflowY: "auto",
              height: `calc(100vh - ${2 * rect.height}px)`,
            }}
          >
            {props.children}
          </div>
        </RulesContext.Provider>
      </ShareProgressContext.Provider>
    </>
  );
};
