import { TextField } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { ColumnContainer } from "../../../components/ColumnContainer";
import {
  Dialog,
  getDialogMethods,
  makeDialog,
} from "../../../components/dialogTools/DialogManager";
import Hider from "../../../components/Hider";
import { JustOkDialog } from "../../../components/JustOkDialog";
import { RowContainer } from "../../../components/RowContainer";
import { Challenge } from "../../../domain/twist_types";
import { FmTextField } from "../../../formManager/FmField";

export interface AnswersDialogProps {
  answerKey: string;
  answers: { words: string[] };
  solutions: Challenge[];
}

let answersDialogInternal: Dialog<void, AnswersDialogProps>;

export const openTwistAnswersDialog = (props: AnswersDialogProps) => {
  if (!answersDialogInternal) {
    answersDialogInternal = makeTwistAnswersDialogComponent();
  }
  return getDialogMethods().open(answersDialogInternal, props);
};

export const makeTwistAnswersDialogComponent = () => {
  return makeDialog<void, AnswersDialogProps>({
    componentRenderer: (dialogRenderProps) => {
      const [answerKey, setAnswerKey] = useState("");
      const props = dialogRenderProps.props;
      const propsAnswerKey = props.answerKey?.toLowerCase();
      const foundAll = props.solutions.length === props.answers.words.length;
      return (
        <JustOkDialog
          onClose={() => {
            dialogRenderProps.close(true);
          }}
          open={true}
          title={"Answers"}
        >
          <ColumnContainer center>
            <Hider hidden={!foundAll}>
              Congratulations: You solved all of the challenges!
            </Hider>
            <Hider hidden={foundAll}>
              <ColumnContainer center>
                To see the answers, enter the answer key below.
                <TextField
                  variant="outlined"
                  value={answerKey}
                  label={"Answer key"}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  onChange={(event) => {
                    setAnswerKey(event.currentTarget.value);
                  }}
                />
              </ColumnContainer>
            </Hider>
            <Hider
              hidden={
                foundAll ||
                answerKey.toLowerCase() !== propsAnswerKey ||
                propsAnswerKey == null
              }
            >
              <ColumnContainer center>
                <Hider hidden={props.answers.words.length === 0}>
                  <ColumnContainer center>
                    <div>You solved:</div>
                    <RowContainer>
                      {props.solutions
                        .filter(
                          (answer) =>
                            props.answers.words.indexOf(answer.solution) >= 0
                        )
                        .map((answer) => AnswerItem(answer.solution))}
                    </RowContainer>
                  </ColumnContainer>
                </Hider>
                <div>You missed:</div>
                <RowContainer>
                  {props.solutions
                    .filter(
                      (answer) =>
                        props.answers.words.indexOf(answer.solution) < 0
                    )
                    .map((answer) => AnswerItem(answer.solution))}
                </RowContainer>
              </ColumnContainer>
            </Hider>
          </ColumnContainer>
        </JustOkDialog>
      );
    },
  });
};

const AnswerItem = (word: string) => {
  return (
    <div key={word} style={{ display: "flex", flexWrap: "wrap" }}>
      <span style={{ marginRight: "0.5rem" }}>
        <b>{word}</b>
      </span>
    </div>
  );
};
