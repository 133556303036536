import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import clsx from "clsx";
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
    },
  });
});
interface ColumnContainerProps {
  center?: boolean;
  className?: string;
}

export const ColumnContainer: React.FunctionComponent<ColumnContainerProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.container, props.className)}
      style={{
        justifyContent: props.center ? "center" : "normal",
        alignItems: props.center ? "center" : "normal",
      }}
    >
      {props.children}
    </div>
  );
};
