import { Box, Container } from "@material-ui/core";
import * as React from "react";
import Hider from "../../../components/Hider";
import {
  ComputePlaySpec,
  createChallenges,
  Op,
} from "../../../domain/compute_types";
import { pluralize } from "../../../utilities";
import { GameInfo } from "./ComputeGameComponent";

export interface ComputeRulesComponentProps {
  playSpec: ComputePlaySpec;
  gameInfo: GameInfo;
}
export const ComputeRulesComponent = (props: ComputeRulesComponentProps) => {
  const { playSpec, gameInfo } = props;
  const rulesPrologue = playSpec.rulesPrologue;
  const rulesPrologueComponent = !rulesPrologue ? null : (
    <Container>{rulesPrologue}</Container>
  );

  const opList: { opLabel: string; opCount: number }[] = [];
  if (playSpec.add) {
    const addChallenges = createChallenges(Op.Add, playSpec.add);
    if (addChallenges.length > 0)
      opList.push({ opLabel: "addition", opCount: addChallenges.length });
  }
  if (playSpec.sub) {
    const subChallenges = createChallenges(Op.Sub, playSpec.sub);
    if (subChallenges.length > 0)
      opList.push({ opLabel: "subtraction", opCount: subChallenges.length });
  }
  if (playSpec.mul) {
    const mulChallenges = createChallenges(Op.Mul, playSpec.mul);
    if (mulChallenges.length > 0)
      opList.push({ opLabel: "multiplication", opCount: mulChallenges.length });
  }
  if (playSpec.div) {
    const divChallenges = createChallenges(Op.Div, playSpec.div);
    if (divChallenges.length > 0)
      opList.push({ opLabel: "division", opCount: divChallenges.length });
  }
  let opsText: string;
  if (opList.length === 1) {
    opsText = `${opList[0].opCount} ${opList[0].opLabel}`;
  } else if (opList.length === 2) {
    opsText =
      `${opList[0].opCount} ${opList[0].opLabel}` +
      " and " +
      `${opList[1].opCount} ${opList[1].opLabel}`;
  } else if (opList.length === 3) {
    opsText =
      `${opList[0].opCount} ${opList[0].opLabel}` +
      ", " +
      `${opList[1].opCount} ${opList[1].opLabel}` +
      ", and " +
      `${opList[2].opCount} ${opList[2].opLabel}`;
  } else if (opList.length === 4) {
    opsText =
      `${opList[0].opCount} ${opList[0].opLabel}` +
      ", " +
      `${opList[1].opCount} ${opList[1].opLabel}` +
      ", " +
      `${opList[2].opCount} ${opList[2].opLabel}` +
      ", and " +
      `${opList[3].opCount} ${opList[3].opLabel}`;
  }

  return (
    <Box>
      {rulesPrologueComponent}
      <Box m={1} />
      <Container>
        <p>The following rules apply to this game:</p>
        <ul style={{ marginTop: 0 }}>
          <li>
            {`The game has ${pluralize(
              playSpec.challengeCount,
              "challenge"
            )}  worth a total of ${pluralize(
              playSpec.challengeCount,
              "point"
            )}.`}
            {gameInfo.highestLevel.score >= gameInfo.maxPoints
              ? ""
              : " You don't need to solve all of the challenges to achieve the top level."}
          </li>
          <li>
            The {pluralize(playSpec.challengeCount, "challenge")} are randomly
            selected from {opsText} problems.
          </li>
          <Hider unmount hidden={!playSpec.timeLimit}>
            <li>
              The game is over after {pluralize(playSpec.timeLimit, "second")}{" "}
              or after you have answered all of the challenges.
            </li>
          </Hider>
          <Hider unmount hidden={!!playSpec.timeLimit}>
            <li>
              The game is over after you have answered all of the challenges.
            </li>
          </Hider>
          <Hider unmount hidden={!playSpec.mustSolve}>
            <li>
              You will not be presented with the next challenge until you answer
              the current challenge correctly.
            </li>
          </Hider>
          <li>
            You can play as many times as you want by clicking PLAY AGAIN.
          </li>
        </ul>
      </Container>
      <Box m={1} />
      <Container>
        The game levels and the number of points needed to reach each level are:{" "}
        {props.playSpec.levels.map((level, index) => (
          <span key={index}>
            {index > 0 ? ", " : ""}
            <b>{level.name}</b> {level.score}
          </span>
        ))}
        .
      </Container>
      <Box m={2} />
    </Box>
  );
};
