import { SpellIcon } from "../components/icons/SpellIcon";
import * as React from "react";
import { ComputeIcon } from "../components/icons/ComputeIcon";
import { TwistIcon } from "../components/icons/TwistIcon";

export interface UserGameSettings {
  modifiedtime: Date;
  templategameid: string;
  hiddenwords: string[];
  extrawords: string[];
}

export interface UserProfile {
  modifiedtime?: Date; // Optional so it doesn't need to be sent in server requests
  initials: string;
  displayName: string;
  isSuperAdmin?: boolean;
}

export type GameType = "s" | "c" | "t";
export const getGameTypeLabel = (gameType: GameType) => {
  switch (gameType) {
    case "s":
      return "Spell";
    case "c":
      return "Compute";
    case "t":
      return "Twist";
  }
  throw new Error("Invalid gametype:" + gameType);
};

export const getGameTypeIcon = (gameType: GameType) => {
  switch (gameType) {
    case "s":
      return React.createElement(SpellIcon, null);
    case "c":
      return React.createElement(ComputeIcon, null);
    case "t":
      return React.createElement(TwistIcon, null);
  }
  throw new Error("Invalid gametype:" + gameType);
};

export const gameTypeHasAnswers = (gameType: GameType) => {
  switch (gameType) {
    case "s":
      return true;
    case "c":
      return false;
    case "t":
      return true;
  }
  throw new Error("Invalid gametype:" + gameType);
};

export const gameTypeUsesMenuRestart = (gameType: GameType) => {
  switch (gameType) {
    case "s":
      return true;
    case "c":
      return false;
    case "t":
      return true;
  }
  throw new Error("Invalid gametype:" + gameType);
};

export interface GameSpec {
  playSpec: PlaySpec;
  authorSpec: AuthorSpec;
}

export enum AutoScoringMethod {
  Slow = "s",
  Medium = "m",
  Fast = "f",
}
export interface AuthorSpec {
  autoScoring: boolean;
  autoScoringMethod: AutoScoringMethod;
  description: string;
}
// GameRecord is the layout in the database.
// Edited games are saved in Draft.
// Publishing copies Draft to Prod.
// We keep name unique in the database.
export interface GameRecord {
  gameType: GameType;
  draft: GameSpec;
  prod: GameSpec;
}

export interface PlaySpec {
  title: string;
  subtitle: string;
  levels: Array<Level>;
  rulesPrologue: string;
  answerKey: string;
}

export interface Level {
  name: string;
  score: number; // min score for this level
}

export const getLevelFromScore = (levels: Array<Level>, score: number) => {
  const reverseIndex = [...levels]
    .reverse()
    .findIndex((level) => (level.score ?? 0) <= score);
  return levels[levels.length - 1 - reverseIndex];
};

// g = game, p=progress
export type GameRefType = "g" | "p";

export interface GameRef {
  id: string; // key into either game collection or progess collection
  refType: GameRefType;
}
