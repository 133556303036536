import { ApiResponseHeader } from "../../../domain/serverContract";
import { PlaySpec } from "../../../domain/types";
import { makeRequest } from "../../../http/http";
import { ErrorResponse } from "../serverContract";

export interface GetGameRequest {
  gameRef: string;
  getProgress: boolean;
  // If game is in local storage already, then this is not the first play.
  isFirstPlay: boolean;
}

export interface GetGameResponse extends ApiResponseHeader {
  playSpec: PlaySpec;
  progress: unknown;
}

interface ResponseInternal extends ErrorResponse, GetGameResponse {}

export async function getGame(
  gameRef: string,
  getProgress: boolean,
  isFirstPlay: boolean
) {
  const request: GetGameRequest = { gameRef, getProgress, isFirstPlay };
  const response = await makeRequest<ResponseInternal>(
    request,
    "/api/get_game"
  );
  // if (response.foundWords == null) response.foundWords = [] as Array<string>;
  // Make sure scoring levels are in order from bottom to top.
  // Also, fill zero for undefined scores.
  response.playSpec.levels = response.playSpec.levels
    .map((level) => {
      return { name: level.name, score: level.score ?? 0 };
    })
    .sort((a, b) => (a.score < b.score ? -1 : a.score > b.score ? 1 : 0));
  return response;
}
