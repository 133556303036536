import { useState, useCallback } from "react";
export function useClientRect() {
  const [rect, setRect] = useState(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect ?? { height: 0, width: 0, x: 0, y: 0 }, ref];
}
