import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useContext, useEffect, useReducer, useState } from "react";
import { SecondaryButton } from "../../../components/buttons";
import { ColumnContainer } from "../../../components/ColumnContainer";
import Hider from "../../../components/Hider";
import { openJustOkDialog } from "../../../components/JustOkDialog";
import {
  notifyErrorTransient,
  notifySuccessTransient,
} from "../../../components/NotificationManager";
import { RowContainer } from "../../../components/RowContainer";
import {
  Challenge,
  TwistPlaySpec,
  TwistProgress,
} from "../../../domain/twist_types";
import { getLevelFromScore, Level } from "../../../domain/types";
import { useFreshState } from "../../../hooks/useFreshState";
import { pluralize } from "../../../utilities";
import { ProgressComponent } from "../../play/components/ProgressComponent";
import { shareProgress } from "../../play/components/ShareProgressComponent";
import {
  RulesContext,
  ShareProgressContext,
} from "../../play/components/TopAppBarComponent";
import { storeProgressLocally } from "../../play/localStorage";
import { GuessInputComponent } from "./GuessInputComponent";
import { TwistRulesComponent } from "./TwistRulesComponent";

const useStyles = makeStyles(() => {
  return createStyles({
    statusCell: {
      padding: "5px",
      backgroundColor: "lightgray",
      minWidth: "12rem",
      margin: "4px 0 4px 0",
    },
  });
});

const smile = "\u{1F600}";

export enum DispatchType {
  Add,
  Delete,
  Clear,
  Pop,
}

export interface Action {
  type: DispatchType;
  letter?: string;
}

export interface AppState {
  pendingAction: Action | undefined;
}

const initialAppState: AppState = { pendingAction: undefined };

function reducer(_state: AppState, action: Action): AppState {
  switch (action.type) {
    case DispatchType.Add:
      return { pendingAction: action };
    case DispatchType.Delete:
      return { pendingAction: action };
    case DispatchType.Clear:
      return { pendingAction: action };
    case DispatchType.Pop:
      return initialAppState;
    default:
      throw new Error();
  }
}

export interface TwistGameComponentProps {
  gameRef: string;
  playSpec: TwistPlaySpec;
  progress: TwistProgress;
  setCurrentProgress: (getProgress: () => TwistProgress) => void;
}

export interface GameInfo {
  maxPoints: number;
  highestLevel: Level;
}

interface InteractiveGameState {
  gameRef: string;
  progress: TwistProgress;
  currentGuess: string;
}

const makeStateFromProps = (
  props: TwistGameComponentProps
): InteractiveGameState => {
  return {
    progress: props.progress ?? {
      words: [],
    },
    gameRef: props.gameRef,
    currentGuess: "",
  };
};

export const TwistGameComponent = (props: TwistGameComponentProps) => {
  const [appState, dispatch] = useReducer(reducer, initialAppState);
  const [gameState, setGameState] = useFreshState(() =>
    makeStateFromProps(props)
  );
  const [firstTime, setFirstTime] = useState(true);
  // When starting is true, we display the start button
  const [finished, setFinished] = useFreshState(() => false);

  const [currentChallenge, setCurrentChallenge] = useState<Challenge>(
    undefined
  );
  const classes = useStyles();

  props.setCurrentProgress(() => gameState().progress);
  const [gameInfo] = useState(
    (): GameInfo => {
      return {
        maxPoints: props.playSpec.challenges.length,
        highestLevel: props.playSpec.levels[props.playSpec.levels.length - 1],
      };
    }
  );

  useEffect(() => {
    // Sets up first challenge.
    setNextChallenge();
  }, []);
  const setNextChallenge = () => {
    // Find the next unsolved challenge after the current challenge.
    // Save the first unsolved challenge in case the player skipped a challenge.
    const currentChallengeIndex =
      currentChallenge == null
        ? -1
        : props.playSpec.challenges.findIndex(
            (c) => c.solution === currentChallenge.solution
          );
    let firstUnsolvedChallenge: Challenge = undefined;
    let nextChallenge: Challenge = undefined;
    for (let i = 0; i < props.playSpec.challenges.length; i++) {
      const thisChallenge = props.playSpec.challenges[i];
      const solved =
        gameState().progress.words.includes(thisChallenge.solution) ?? false;
      if (firstUnsolvedChallenge === undefined && !solved) {
        firstUnsolvedChallenge = thisChallenge;
      }
      if (!solved && i > currentChallengeIndex) {
        nextChallenge = thisChallenge;
        break;
      }
    }
    nextChallenge = nextChallenge ?? firstUnsolvedChallenge;
    if (nextChallenge == null) {
      setFinished(true);
    }
    setCurrentChallenge(nextChallenge);
  };

  // // Get a new challenge each time the challengeCounter changes.
  // useEffect(() => {
  //   setNextChallenge();
  // }, [challengeCounter, starting]);

  const remainingChallengeCount =
    props.playSpec.challenges.length - gameState().progress.words.length;

  const displayRules = () => {
    openJustOkDialog({
      content: (
        <TwistRulesComponent gameInfo={gameInfo} playSpec={props.playSpec} />
      ),
      title: "Rules",
    });
  };

  useEffect(() => {
    if (currentChallenge === undefined && !finished()) displayRules();
  }, []);

  useContext(ShareProgressContext)(() =>
    shareProgress(gameState().gameRef, gameState().progress)
  );

  useContext(RulesContext)(displayRules);

  useEffect(() => {
    // Save state to local storage
    // Only when the correct answers have changed
    // Don't write the first time, because we already either read or wrote the state
    if (firstTime) {
      setFirstTime(false);
      return;
    }
    storeProgressLocally(
      gameState().gameRef,
      (data) => {
        data.progress = gameState().progress;
        data.unmodified = false;
      },
      false // means not updated shared progress
    );
  }, [gameState().progress]);

  const [score, setScore] = useState(0);
  useEffect(() => {
    // Update score when answers changes
    const score = gameState().progress.words.length;
    setScore(score);
  }, [gameState().progress.words]);

  const [level, setLevel] = useState(props.playSpec.levels[0]);

  const submitHandler = (guess: string) => {
    if (guess === "") return;
    if (finished()) {
      notifySuccessTransient("The game is finished.");
      return;
    }
    const isCorrect = props.playSpec.caseSensitive
      ? currentChallenge.solution === guess
      : currentChallenge.solution.toLowerCase() === guess.toLowerCase();
    if (isCorrect) {
      notifySuccessTransient(`Correct! ${smile}`);
    } else {
      notifyErrorTransient(`${guess} is not correct`);
      return;
    }
    const newGameState = {
      ...gameState(),
      currentGuess: "",
      progress: {
        words: [...gameState().progress.words, currentChallenge.solution],
      },
    };
    setGameState(newGameState);
    dispatch({ type: DispatchType.Clear });

    const nextLevel = getLevelFromScore(props.playSpec.levels, score + 1);
    const isNewLevel = nextLevel.score !== level.score;
    if (isNewLevel) {
      // openJustOkDialog({content: achievementLevelElement, title: "Congrats!"})
      //setShowAchievementLevel(true); //********************************************
    }

    setLevel(nextLevel);
    setNextChallenge();
  };

  return (
    <RowContainer center>
      <ColumnContainer center>
        <Typography variant="subtitle1">{props.playSpec.title}</Typography>
        <Typography variant="subtitle2">{props.playSpec.subtitle}</Typography>
        <Box m={0.2} />
        <Hider hidden={!finished()}>
          <Typography variant="h4" color="secondary">
            DONE!
          </Typography>
        </Hider>

        <Box m={0.2} />
        <Hider unmount hidden={finished() || currentChallenge == null}>
          <ColumnContainer center>
            <GuessInputComponent
              challenge={currentChallenge}
              caseSensitive={props.playSpec.caseSensitive}
              onSubmit={(guess: string) => {
                submitHandler(guess);
              }}
            />
            <Box m={1} />
            <SecondaryButton
              disabled={remainingChallengeCount <= 1}
              onClick={() => setNextChallenge()}
            >
              Skip
            </SecondaryButton>
          </ColumnContainer>
        </Hider>

        <ColumnContainer className={classes.statusCell} center>
          <div>{remainingChallengeCount}</div>
          <div>Challenges Remaining</div>
        </ColumnContainer>

        <ProgressComponent levels={props.playSpec.levels} score={score} />
        <div>
          You have {pluralize(score, "correct answer")} for{" "}
          {pluralize(score, "point")}
        </div>
        <RowContainer center>
          {gameState().progress.words.map((word, i) => {
            return (
              <div style={{ margin: "0 1rem 0 0" }} key={i}>
                {word}
              </div>
            );
          })}
        </RowContainer>
      </ColumnContainer>
    </RowContainer>
  );
};
