import { PlaySpec, GameType, AuthorSpec } from "./types";

export interface Answer {
  word: string;
  score: number;
}

export interface OperationDomain {
  // The lhs is the text entered by the author to describe the range of the Lhs operands.
  lhs: string;
  // The rhs is the text entered by the author to describe the range of the Rhs operands.
  rhs: string;
}

export const NewOperationDomain = (): OperationDomain => {
  return {
    lhs: "",
    rhs: "",
  };
};

export interface ComputePlaySpec extends PlaySpec {
  add: OperationDomain;
  sub: OperationDomain;
  mul: OperationDomain;
  div: OperationDomain;
  // timeLimit is the number of seconds until the game ends.
  // If undefined, then there is no time limit.
  timeLimit: number;
  // ChallengeCount is the number of challenges in a game.
  challengeCount: number;
  // MustSolve true means each challenge must be solved before the next challenge is presented.
  mustSolve: boolean;
}

// GameAuthorSpec has the data needed to author a game
export interface ComputeAuthorSpec extends AuthorSpec {}

export interface ComputeSpec {
  playSpec: ComputePlaySpec;
  authorSpec: ComputeAuthorSpec;
}

// ComputeRecord is the layout in the database.
// Edited games are saved in Draft.
// Publishing copies Draft to Prod.
// We keep name unique in the database.
export interface ComputeRecord {
  gameType: GameType;
  draft: ComputeSpec;
  prod: ComputeSpec;
}

export enum Op {
  Add = "+",
  Sub = "-",
  Mul = "*",
  Div = "/",
}

export const getOpChar = (op: Op) => {
  switch (op) {
    case Op.Add:
      return "+";
    case Op.Mul:
      return "×";
    case Op.Div:
      return "÷";
    case Op.Sub:
      return "-";
  }
};

// Challenge is a problem.
export interface Challenge {
  op: Op;
  lhs: number;
  rhs: number;
}

// Chaser is short for ChallengeAnswer.
// It holds the challenge and the player's answer (which could be right or wrong).
// Skipped is true if the user skipped the challenge.
export interface Chaser {
  challenge: Challenge;
  answer: number;
  skipped: boolean;
}

export interface ComputeProgress {
  chasers: Chaser[];
  elapsedSeconds: number;
}

// solve returns the correct answer for a challenge.
export function solve(c: Challenge) {
  switch (c.op) {
    case Op.Mul:
      return c.lhs * c.rhs;
    case Op.Div:
      return c.lhs / c.rhs;
    case Op.Add:
      return c.lhs + c.rhs;
    case Op.Sub:
      return c.lhs - c.rhs;
  }
}

export interface OperandRange {
  start: number;
  end: number;
}
const validSingle = new RegExp(String.raw`^[0-9]+$`);
const validRange = new RegExp(String.raw`^[0-9]+-[0-9]+$`);
const hyphenWithWhitespace = new RegExp(String.raw`\s*-\s*`, "g");
const whitespace = new RegExp(String.raw`\s+`, "g");

// Compile compiles a text list of possible operand values into an OperandRange structure.
// Lists consist of one or more comma-separated or whitespace-separated tokens.
// Each token may be a decimal number, or two decimal numbers separated by a asterisk.
// Asterisk-separated numbers translate to ranges that start with the smaller number and end with the larger,
// meaning that order doesn't matter. Specifying the same start and end is identical to specifying a single number.
// Decimal numbers may not exceed 1000.
// There are equivalent:
// * 1-2,4,6-10
// * 1 - 2,4  6-10
// * 1-2 4 6-10

// Generously interpret operand ranges:
// * Ignore duplicates, including overlapping ranges
// * Allow ranges to go backwards
// * Preserve range list as typed by user.

function compile(t: string): OperandRange[] {
  // * 1 - 2,4  6-10
  // Replace asterisk surrounded by whitespace with just a hyphen
  // After trimming whitespace
  let s = t.trim().replace(hyphenWithWhitespace, "-");
  // * 1-2,4  6-10
  // Replace whitespace with a single comma
  s = s.replace(whitespace, ",");
  // * 1-2,4,6-10
  const tokens = s.split(",");
  const r: OperandRange[] = [];
  for (let i = 0; i < tokens.length; i++) {
    if (validSingle.test(tokens[i])) {
      const n = convertOperandToNumber(tokens[i]);
      r.push({ start: n, end: n });
    } else if (validRange.test(tokens[i])) {
      const ts = tokens[i].split("-");
      const n = convertOperandToNumber(ts[0]);
      const m = convertOperandToNumber(ts[1]);
      if (n <= m) {
        r.push({ start: n, end: m });
      } else {
        r.push({ start: m, end: n });
      }
    }
  }
  return r;
}

function convertOperandToNumber(s: string) {
  const n = Number(s);
  if (n > 1000) {
    throw new Error("Range value is greater than 1000.");
  }
  return n;
}

export function createAllChallenges(playSpec: ComputePlaySpec) {
  const challenges: Challenge[] = [];
  if (playSpec.add) {
    challenges.push(...createChallenges(Op.Add, playSpec.add));
  }
  if (playSpec.sub) {
    challenges.push(...createChallenges(Op.Sub, playSpec.sub));
  }
  if (playSpec.mul) {
    challenges.push(...createChallenges(Op.Mul, playSpec.mul));
  }
  if (playSpec.div) {
    challenges.push(...createChallenges(Op.Div, playSpec.div));
  }
  return challenges;
}

// createChallenges returns an array of all of the challenges given an operation and a domain.
export function createChallenges(op: Op, od: OperationDomain) {
  const lhs = compile(od.lhs);
  const rhs = compile(od.rhs);
  const challenges: Record<string, Challenge> = Object.create(null);
  lhs.forEach((lhsRange) => {
    for (let loperand = lhsRange.start; loperand <= lhsRange.end; loperand++) {
      rhs.forEach((rhsRange) => {
        for (
          let roperand = rhsRange.start;
          roperand <= rhsRange.end;
          roperand++
        ) {
          const challenge: Challenge = { op, lhs: loperand, rhs: roperand };
          switch (op) {
            case Op.Div:
              if (solve(challenge) % 1 !== 0) continue;
              break;
            case Op.Sub:
              if (solve(challenge) < 0) continue;
              break;
          }
          challenges[op + loperand + "|" + roperand] = challenge;
        }
      });
    }
  });
  return Object.values(challenges);
}
