import { Button } from "@material-ui/core";
import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => {
  return createStyles({
    cell: {
      margin: "4px 4px 4px 4px",
      textTransform: "none",
      height: "2rem",
    },
  });
});
export interface GuessInputButtonComponentProps {
  letter: string;
  isAvailable: boolean;
  onClick: () => void;
}

// InputButtonComponent presents a button in a custom keyboard.
export const GuessInputButtonComponent = (
  props: GuessInputButtonComponentProps
) => {
  const classes = useStyles();
  const { isAvailable, letter, onClick } = props;
  return (
    <Button
      className={classes.cell}
      variant="contained"
      color={isAvailable ? "primary" : "secondary"}
      onClick={() => {
        onClick();
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>{letter}</div>
      </div>
    </Button>
  );
};
