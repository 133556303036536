import { Box, Paper } from "@material-ui/core";
import * as React from "react";

export const PlayTerms = () => {
  return (
    <Box m={1}>
      <Paper>
        <Box m={1}>
          These terms describes what you can expect from us (Cortex Play) and
          what we expect from you. It is effective as of 13 August 2020.
          Questions about this policy should be emailed to terms @
          cortexplay.com.
          <ul>
            <li>
              What you can expect from us:
              <ul>
                <li>games designed to be both enjoyable and educational</li>
                <li>good game performance and availability</li>
                <li>no advertising or collection of personal information</li>
              </ul>
            </li>
            <li>
              What we expect from you:
              <ul>
                <li>
                  not to use the game for any purpose other than playing. For
                  example, you may not attempt to disrupt the game for other
                  users by starting a denial of service attack on the game web
                  site.
                </li>
              </ul>
            </li>
          </ul>
        </Box>
      </Paper>
    </Box>
  );
};
