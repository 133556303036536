import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import * as React from "react";
import { useState } from "react";
type Severity = "error" | "info" | "success" | "warning";

export let notifyError: (message: string, callback?: () => void) => void;

export let notifySuccess: (message: string, callback?: () => void) => void;

export let notifyErrorTransient: (
  message: string,
  callback?: () => void
) => void;

export let notifySuccessTransient: (
  message: string,
  callback?: () => void
) => void;

const nullCallback = () => {};

interface NotificationManagerProps {}
export const NotificationManager: React.FunctionComponent<NotificationManagerProps> = (
  props
) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<Severity>(undefined);
  const [callback, setCallback] = useState<() => void>(undefined);
  const [autoHideDuration, setAutoHideDuration] = useState(0);
  const notify = (
    message: string,
    severity: Severity,
    callback: () => void,
    autoHideDuration: number
  ) => {
    if (typeof message !== "string") {
      // try message.message
      if (typeof (message as any)?.message !== "string") {
        console.error("notify expected string, got " + typeof message, message);
        setMessage("Error detected");
      }
      message = (message as any).message;
    }
    setMessage(message);
    setCallback(callback ?? nullCallback);
    setSeverity(severity);
    setAutoHideDuration(autoHideDuration);
    setShow(true);
  };

  notifyError = (message: string, callback?: () => void) =>
    notify(message, "error", callback, 0);
  notifySuccess = (message: string, callback?: () => void) =>
    notify(message, "success", callback, 0);
  notifyErrorTransient = (message: string, callback?: () => void) =>
    notify(message, "error", callback, 2000);
  notifySuccessTransient = (message: string, callback?: () => void) =>
    notify(message, "success", callback, 2000);

  return (
    <NotifySnackbarComponent
      severity={severity}
      message={message}
      show={show}
      onShow={() => setShow(false)}
      onClose={callback}
      autoHideDuration={autoHideDuration}
    />
  );
};

function NotifySnackbarComponent(props: {
  message: JSX.Element | string;
  show: boolean;
  onShow: () => void;
  severity: Severity;
  onClose: () => void;
  autoHideDuration: number;
}) {
  const [open, setOpen] = React.useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    setOpen(false);
    props.onClose && props.onClose();
  };

  React.useEffect(() => {
    if (props.show) {
      setOpen(true);
      props.onShow();
    }
  });
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={
        props.autoHideDuration === 0 ? null : props.autoHideDuration
      }
    >
      <Alert severity={props.severity}>{props.message}</Alert>
    </Snackbar>
  );
}
