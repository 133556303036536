import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SubtleButton } from "../buttons";
import { getDialogMethods, makeDialog, Dialog } from "./DialogManager";

export interface ConfirmationDialogBaseProps {
  open: boolean;
  onClose: (isOk: boolean) => void;
  title: string;
  okayText?: string;
}

export const ConfirmationDialogBase: React.FunctionComponent<ConfirmationDialogBaseProps> = (
  props
) => {
  const { onClose, open, okayText, title } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };
  const history = useHistory();
  useEffect(() => {
    // Some apps may not use router.
    if (!history) return;
    // To start listening for location changes...
    return history.listen(() => {
      onClose(false);
    });
  }, []);

  return (
    <MuiDialog
      disableBackdropClick
      // disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={handleCancel}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        <SubtleButton autoFocus onClick={handleCancel}>
          Cancel
        </SubtleButton>
        <SubtleButton onClick={handleOk}>{okayText ?? "Ok"}</SubtleButton>
      </DialogActions>
    </MuiDialog>
  );
};
