import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { AppState, DispatchType } from "./ComputeGameComponent";

export interface GuessNumberInputComponentProps {
  onChange: (guess: string) => void;
  appState: AppState;
  dispatchPopAction: () => void;
}
export const GuessNumberInputComponent = (
  props: GuessNumberInputComponentProps
) => {
  const { onChange, appState, dispatchPopAction } = props;

  const [guess, setGuessState] = useState("");
  const setGuess = (guess: string) => {
    setGuessState(guess);
    onChange(guess);
  };
  const inputRef = useRef(null);
  const [insertPositionState, setInsertPositionState] = useState(0);

  const insertPosition =
    appState.pendingAction && isMobile === false
      ? inputRef?.current?.selectionStart ?? 0
      : insertPositionState;

  const setInsertPosition = (newInsertPosition: number) => {
    setInsertPositionState(newInsertPosition > 0 ? newInsertPosition : 0);
  };

  useEffect(() => {
    const inputElement: HTMLInputElement = inputRef.current;
    inputElement.focus();
    if (isMobile) {
      inputElement.selectionStart = inputRef.current.value.length;
      inputElement.selectionEnd = inputRef.current.value.length;
    } else {
      inputElement.selectionStart = insertPosition;
      inputElement.selectionEnd = insertPosition;
    }
  });

  useEffect(() => {
    switch (appState.pendingAction?.type) {
      case DispatchType.Delete:
        dispatchPopAction();
        if (insertPosition === 0) return;
        setGuess(
          guess.slice(0, insertPosition - 1) + guess.slice(insertPosition)
        );
        setInsertPosition(insertPosition - 1);
        break;
      case DispatchType.Add:
        setGuess(
          guess.slice(0, insertPosition) +
            appState.pendingAction.letter +
            guess.slice(insertPosition)
        );
        setInsertPosition(insertPosition + 1);
        dispatchPopAction();
        break;
      case DispatchType.Clear:
        setGuess("");
        setInsertPosition(0);
        dispatchPopAction();
        break;
    }
  });

  return (
    <TextField
      variant="filled"
      label="Your guess"
      inputRef={inputRef}
      InputProps={{
        readOnly: isMobile,
        style: {
          fontFamily: '"Rubik", "Roboto", "Helvetica", "Arial", sans-serif',
        },
        spellCheck: false,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear input field"
              onClick={() => {
                setGuess("");
                setInsertPosition(0);
              }}
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={guess + (isMobile ? "|" : "")}
      onChange={(event) => {
        let newGuess = event.target.value;
        if (newGuess.length > 10) return;
        if (isMobile) newGuess = newGuess.substring(0, newGuess.length - 1);
        const badLetter = [...newGuess].find((letter) => {
          if ("1234567890".indexOf(letter) >= 0) return false;
          return letter;
        });
        if (badLetter === undefined) {
          setGuess(newGuess);
          setInsertPosition(event.target.selectionStart);
        } else {
          setInsertPosition(event.target.selectionStart - 1);
        }
      }}
    />
  );
};
