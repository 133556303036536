import {
  AppBar,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Toolbar,
} from "@material-ui/core";
import { Copyright } from "@material-ui/icons";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { openJustOkDialog } from "../../../components/JustOkDialog";
import { CommonSnackbar } from "../../../components/dialogTools/snackbars";
import { PlaySpec } from "../../../domain/types";
import { useClientRect } from "../../../measure";
import { Answer } from "../../author/games/spell/spellTypes";
import { openSpellAnswersDialog } from "../../spell/components/SpellAnswers";
import { SpellGameComponent } from "../../spell/components/SpellGameComponent";
import { SpellPlaySpec, SpellProgress } from "../../spell/spell_types";
import {
  localStorageAvailable,
  readStateFromLocalStorage,
  storeProgressLocally,
} from "../localStorage";
import { PlayPrivacy } from "../PlayPrivacy";
import { PlayTerms } from "../PlayTerms";
import { getGame, GetGameResponse } from "../requests/getGame";
import { getGameRefFromLocation } from "../url";
import { getGameType } from "../globalPlayData";
import { ComputeGameComponent } from "../../compute/components/ComputeGameComponent";
import {
  ComputePlaySpec,
  ComputeProgress,
} from "../../../domain/compute_types";
import { TopAppBarComponent } from "./TopAppBarComponent";
import { TwistGameComponent } from "../../twist/components/TwistGameComponent";
import {
  TwistPlaySpec,
  TwistProgress,
  Challenge,
} from "../../../domain/twist_types";
import { openTwistAnswersDialog } from "../../twist/components/TwistAnswers";

const getGameFromGamePath = async (): Promise<GetGameResponse> => {
  const gameRef = getGameRefFromLocation();
  let progress: unknown;
  let isFirstPlay = true;
  if (localStorageAvailable()) {
    try {
      const storedGameState = readStateFromLocalStorage<unknown>(gameRef);
      if (storedGameState !== null && storedGameState.gameRef === gameRef) {
        progress = storedGameState.progress;
        isFirstPlay = false;
      }
    } catch (_e) {} // fall through to get from server
  }
  try {
    // If we already have gameProgress, then we don't need to ask for it
    let persistentStorageGameState = await getGame(
      gameRef,
      progress == undefined,
      isFirstPlay
    );
    // Use local progress if we have it
    if (progress) {
      persistentStorageGameState = {
        ...persistentStorageGameState,
        progress,
      };
    }
    storeProgressLocally(
      gameRef,
      (data) => {
        if (!progress) data.progress = persistentStorageGameState.progress;
        data.unmodified = true;
      },
      false // means not updated shared progress
    );
    return persistentStorageGameState;
  } catch (e) {
    throw new Error(e);
  }
};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    bottomAppBar: {
      top: "auto",
      bottom: 0,
    },
  })
);

interface GamePageComponentProps {}
export const GamePageComponent = (props: GamePageComponentProps) => {
  const [gameState, setGameState] = useState<{
    playSpec: PlaySpec;
    progress: unknown;
  }>();
  const [gameRef, setGameRef] = useState<string>();
  const [errorMessage, setErrorMessage] = useState(undefined as string);
  // Run only if any props change
  useEffect(() => {
    setGameRef(getGameRefFromLocation());
    const asyncFunc = async () => {
      try {
        const storableGameState = await getGameFromGamePath();
        setGameState({
          playSpec: storableGameState.playSpec,
          progress: storableGameState.progress,
        });
      } catch (e) {
        setErrorMessage(e.message);
      }
    };
    asyncFunc();
  }, [props]);

  const classes = useStyles();

  const snackBar =
    errorMessage == null ? null : (
      <CommonSnackbar
        severity="error"
        message={errorMessage}
        closeFunc={() => setErrorMessage(undefined)}
      />
    );
  const dataPresent = gameState !== undefined;

  const getProgressRef = useRef<() => unknown>();

  let gameComponent: JSX.Element = null;

  if (dataPresent) {
    switch (getGameType()) {
      case "s":
        gameComponent = (
          <SpellGameComponent
            gameRef={gameRef}
            playSpec={gameState.playSpec as SpellPlaySpec}
            progress={gameState.progress as SpellProgress}
            setCurrentProgress={(getProgress: () => unknown) => {
              getProgressRef.current = getProgress;
            }}
          />
        );
        break;
      case "c":
        gameComponent = (
          <ComputeGameComponent
            gameRef={gameRef}
            playSpec={gameState.playSpec as ComputePlaySpec}
            progress={gameState.progress as ComputeProgress}
            setCurrentProgress={(getProgress: () => unknown) => {
              getProgressRef.current = getProgress;
            }}
          />
        );
        break;
      case "t":
        gameComponent = (
          <TwistGameComponent
            gameRef={gameRef}
            playSpec={gameState.playSpec as TwistPlaySpec}
            progress={gameState.progress as TwistProgress}
            setCurrentProgress={(getProgress: () => unknown) => {
              getProgressRef.current = getProgress;
            }}
          />
        );
        break;
    }
  }
  const [rect, ref] = useClientRect();
  return (
    <>
      <TopAppBarComponent
        answers={() => {
          switch (getGameType()) {
            case "s":
              openSpellAnswersDialog({
                answerKey: gameState.playSpec.answerKey,
                foundWords: getProgressRef.current() as string[],
                answers: (gameState.playSpec as SpellPlaySpec).answers,
              });
              break;
            case "t":
              openTwistAnswersDialog({
                answerKey: gameState.playSpec.answerKey,
                answers: getProgressRef.current() as { words: string[] },
                solutions: (gameState.playSpec as TwistPlaySpec)
                  .challenges as Challenge[],
              });
              break;
          }
        }}
      >
        {gameComponent}
        {snackBar}
      </TopAppBarComponent>

      <AppBar className={classes.bottomAppBar} ref={ref}>
        <Toolbar>
          <Grid container alignItems="center">
            <Copyright fontSize="inherit" />
            2020 Cortex Play
          </Grid>
          <Button
            color="inherit"
            onClick={() =>
              openJustOkDialog({
                content: <PlayPrivacy />,
                title: "Privacy",
              })
            }
          >
            Privacy
          </Button>
          <Button
            color="inherit"
            onClick={() =>
              openJustOkDialog({
                content: <PlayTerms />,
                title: "Terms of Service",
              })
            }
          >
            Terms
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};
